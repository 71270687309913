import React, { ReactElement, useEffect } from "react";
import MenuItem from "../../components/MenuItem";
import { IconButton, Box, Stack, Grid } from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import { Close } from "@mui/icons-material";
import translations from "translations";
import { useNavigate } from "react-router-dom";
import AllDetections from "./AllDetections";
import WorkflowStatus from "./WorkflowStatus";
import StatusBar from "components/StatusBar";
import DetectionCategories from "./DetectionCategories";
import IssueCategories from "./IssueCategories";
import MachineDetectionCategories from "./MachineDetectionCategories";
import MachineDetectionCategoriesChart from "./MachineDetectionCategoriesChart";
import ImageCounter from "./Counters/ImageCounter";
import FlaggedImageCounter from "./Counters/FlaggedImageCounter";
import PoleCounter from "./Counters/PoleCounter";
import SupervisorReviewCounter from "./Counters/SupervisorReviewCounter";
import MachineReviewCounter from "./Counters/MachineReviewCounter";
import CounterTemplate from "./Counters/Template";
import "./style.scss";
import PowerlineLidarLength from "./PowerlineLidarLength";
import GanttChartWrapper from "components/GanttChart";
import { useLanguage } from "hooks";

interface Props {
  customerId: number;
  gridLength: number;
  inspectedLength: number;
  reflightLength: number;
  imageLength: number;
  skyqraft_employee: boolean;
  updateGridStatistics: () => void;
  updateDefectMetrics: Function;
  metrics: { defectMetrics: any; timestamp: string };
}

export default function Statistics({
  customerId,
  updateGridStatistics,
  gridLength,
  inspectedLength,
  reflightLength,
  imageLength,
  skyqraft_employee,
  metrics,
  updateDefectMetrics,
}: Props): ReactElement {
  const { language } = useLanguage();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isNaN(customerId) && customerId > 0) {
      updateGridStatistics();
      updateDefectMetrics();
    }
  }, [customerId, updateGridStatistics, updateDefectMetrics]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "#EEE",
        overflowY: "auto",
        zIndex: 100,
      }}
    >
      <StatusBar title={translations.Menu.Statistics[language]} />
      <Stack
        p={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        flex={1}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Close />
        </IconButton>
      </Stack>

      {customerId < 0 ? (
        <h2 style={{ textAlign: "center" }}>
          All mission statistics comming soon. Please go back and select a
          mission to view it's stats.
        </h2>
      ) : (
        <div id="statistics">
          <GanttChartWrapper projectID={customerId} />

          <Grid
            container
            sx={{ mx: { xs: 1, sm: 1, md: 2, lg: 3, xl: 5 } }}
            rowSpacing={2}
            columnSpacing={2}
          >
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <ImageCounter />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <FlaggedImageCounter />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <PoleCounter />
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <CounterTemplate
                title={translations.Menu.Statistics.Powergrid.Images[language]}
                value={`${Math.round(imageLength / 1000)} km`}
                loading={false}
                success={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <PowerlineLidarLength />
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <CounterTemplate
                title={translations.Menu.Statistics.Powergrid.Length[language]}
                value={`${Math.round(gridLength / 1000)} km`}
                loading={false}
                success={true}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <CounterTemplate
                title={
                  translations.Menu.Statistics.Powergrid.Inspected[language]
                }
                value={`${Math.round(inspectedLength / 1000)} km`}
                loading={false}
                success={true}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
              <CounterTemplate
                title={
                  translations.Menu.Statistics.Powergrid.Reflight[language]
                }
                value={`${Math.round(reflightLength / 1000)} km`}
                loading={false}
                success={true}
              />
            </Grid>
          </Grid>
          <RoleWrapper keyName="machineStatistics">
            <>
              <AllDetections />

              <WorkflowStatus />

              <DetectionCategories />

              <IssueCategories />

              <MachineDetectionCategories />

              <MachineDetectionCategoriesChart />

              {skyqraft_employee && metrics.defectMetrics.length > 0 && (
                <>
                  <MenuItem className="section" passive>
                    <p>
                      {translations.Menu.Statistics.MachineLearning[language]}
                    </p>
                  </MenuItem>
                  <pre
                    style={{
                      fontSize: 14,
                      width: "100%",
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 4,
                      marginBottom: 4,
                      textAlign: "center",
                    }}
                  >
                    {
                      translations.Menu.Statistics.MachineLearning.Updated[
                        language
                      ]
                    }{" "}
                    {metrics.timestamp}
                  </pre>
                  {/* @ts-ignore */}
                  {metrics.defectMetrics.map((metric, i) => (
                    <div key={i}>
                      <MenuItem className="section" passive>
                        <p>{metric.type_name}</p>
                      </MenuItem>
                      <MenuItem passive>
                        <p>
                          {
                            translations.Menu.Statistics.MachineLearning
                              .Accuracy[language]
                          }
                        </p>
                        <p>{Math.round(10000 * metric.accuracy) / 100}%</p>
                      </MenuItem>
                      <MenuItem passive>
                        <p>
                          {
                            translations.Menu.Statistics.MachineLearning
                              .Precision[language]
                          }
                        </p>
                        <p>{Math.round(10000 * metric.precision) / 100}%</p>
                      </MenuItem>
                      <MenuItem passive>
                        <p>
                          {
                            translations.Menu.Statistics.MachineLearning.Recall[
                              language
                            ]
                          }
                        </p>
                        <p>{Math.round(10000 * metric.recall) / 100}%</p>
                      </MenuItem>
                      <MenuItem passive>
                        <p>
                          {
                            translations.Menu.Statistics.MachineLearning
                              .WorkReduction[language]
                          }
                        </p>
                        <p>{Math.round(10000 * metric.work_red) / 100}%</p>
                      </MenuItem>
                    </div>
                  ))}
                </>
              )}
            </>
          </RoleWrapper>
        </div>
      )}
    </Box>
  );
}
