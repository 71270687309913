export const accessLevels = {
  2: {
    EN: "Admin",
    SE: "Admin",
    NO: "Admin",
  },
  5: {
    EN: "Write",
    SE: "Skriva",
    NO: "Skrive",
  },
  6: {
    EN: "Upload",
    SE: "Ladda upp",
    NO: "Last opp",
  },
  8: {
    EN: "Viewer",
    SE: "Läsa",
    NO: "Les",
  },
  9: {
    EN: "External Annotator",
    SE: "Extern Annoterare",
    NO: "Extern Annotatør",
  },
};

export const accessLevelsHidden = {
  ...accessLevels,
  1: {
    EN: "Owner",
    SE: "Ägare",
    NO: "Eier",
  },
  3: {
    EN: "Supervisor",
    SE: "Handledare",
    NO: "Veileder",
  },
  4: {
    EN: "Annotator",
    SE: "Annoterare",
    NO: "Annotatør",
  },
  7: {
    EN: "API",
    SE: "API",
    NO: "API",
  },
};
