import {
  Box,
  IconButton,
  Paper,
  Stack,
  Typography,
  Card,
  Button,
} from "@mui/material";
import { useContext, useState, useRef } from "react";
import { SummaryContext } from "views/PoleViewer/provider";
import PoleComponentItem from "./PoleComponentItem";
import PolePlacementItem from "./PolePlacementItem";
import Templates from "./Templates";
import { Add } from "@mui/icons-material";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import AddPoleComponentPopup from "./AddPoleComponentPopup";
import AddComponentPlacementPopup from "./AddComponentPlacementPopup";
import PoleComment from "../PoleComment";
import {
  setThumbnailRegexPattern,
  setThumbnailPoleItemPlacement,
} from "state/actions";
import { useDispatch } from "hooks";
import CircleDot from "./CircleDot";
import { RoleWrapper } from "components";

export default function PoleContents() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [addNewComponentDialog, setAddNewComponentDialog] = useState(false);
  const dispatch = useDispatch();
  const pathParams = useParams();
  const navigate = useNavigate();
  // @ts-ignore
  const mission = parseInt(pathParams["mission"]);
  // @ts-ignore
  const imageID = parseInt(pathParams["image"]);
  const imageObjectIDs = searchParams.get("boxIds")?.split(",") || [];

  const selectedComponentID = searchParams.has("componentID")
    ? parseInt(searchParams.get("componentID")!)
    : null;
  const selectedPlacementID = searchParams.has("placementID")
    ? parseInt(searchParams.get("placementID")!)
    : null;
  const setSelectedComponentID = (value: number | null) => {
    if (value) {
      searchParams.set("componentID", value.toString());
    } else {
      searchParams.delete("componentID");
    }
    setSearchParams(searchParams);
  };

  const [addNewPlacementDialog, setAddNewPlacementDialog] = useState(false);
  const summaryContext = useContext(SummaryContext);

  const selectedComponent = summaryContext.components.find(
    (c) => c.id === selectedComponentID
  );

  const itemSummary = summaryContext.components
    .sort((a, b) => a.id - b.id)
    .map((c) => {
      return {
        id: c.id,
        name: c.name,
        status: c.placements.sort((a, b) => a.id - b.id),
      };
    });

  // Create a ref for the placement section
  const placementRef = useRef<HTMLDivElement>(null);

  // Function to scroll to the placement section
  const scrollToPlacement = () => {
    if (placementRef.current) {
      placementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  type ComponentType = (typeof itemSummary)[number];
  type PlacementType =
    (typeof summaryContext.components)[number]["placements"][number];

  const placementClick = (
    e: React.MouseEvent<HTMLDivElement>,
    component: ComponentType | null,
    placement: PlacementType
  ) => {
    e.stopPropagation();
    if (placement.id === selectedPlacementID) {
      searchParams.delete("placementID");
      searchParams.delete("boxIds");
      setSearchParams(searchParams);
      if (component !== null) {
        setSelectedComponentID(null);
      }
      dispatch(setThumbnailRegexPattern(null));
      dispatch(setThumbnailPoleItemPlacement(null));
      return;
    }
    searchParams.set("placementID", placement.id?.toString());
    const boxIds = placement?.image_object_id?.toString() || "";

    if (boxIds !== "") {
      searchParams.set("boxIds", boxIds);
    } else {
      searchParams.delete("boxIds");
    }
    if (component !== null) {
      setSelectedComponentID(component.id);
    }

    setSearchParams(searchParams);

    if (placement.image_id !== imageID || !imageObjectIDs.includes(boxIds)) {
      if (placement.image_id !== null) {
        const newSearchParams = new URLSearchParams(searchParams);
        navigate(
          `/${mission}/${placement.image_id}?${newSearchParams.toString()}`
        );
      }
    }
    // Update regex as well
    dispatch(setThumbnailRegexPattern(placement.regex_pattern));
    dispatch(setThumbnailPoleItemPlacement(placement.id));
  };

  return (
    <Paper>
      <Box>
        <Box p={2} height="fit-content">
          <Box>
            <Card>
              <Typography margin={"15px"}>
                <b>Overview</b>
              </Typography>

              <Stack spacing={1}>
                <Stack
                  sx={{
                    zIndex: 90,
                    p: 1,
                    cursor: "pointer",
                    background: "rgba(255,255,255,0.5)",
                  }}
                  alignItems="flex-start"
                >
                  {itemSummary.length === 0 ? (
                    <Typography variant="caption">Click to report</Typography>
                  ) : (
                    <>
                      {itemSummary.map((item) => (
                        <Stack
                          key={item.id}
                          direction="row"
                          alignItems="center"
                        >
                          <Typography sx={{ width: 100 }} variant="caption">
                            <Button
                              size="small"
                              onClick={() =>
                                setSelectedComponentID(
                                  selectedComponentID === item.id
                                    ? null
                                    : item.id
                                )
                              }
                              sx={{
                                color: "black",
                                fontSize: "11px",
                                textTransform: "none",
                              }}
                              fullWidth={true}
                            >
                              {item.name}:
                            </Button>
                          </Typography>
                          <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                            {item.status.map((status, index) => {
                              return (
                                <CircleDot
                                  status={status}
                                  item={item}
                                  // @ts-ignore
                                  selectedPlacementID={selectedPlacementID}
                                  placementClick={placementClick}
                                />
                              );
                            })}
                          </Stack>
                        </Stack>
                      ))}
                    </>
                  )}
                </Stack>
              </Stack>
            </Card>
          </Box>
        </Box>

        <Box p={2} height="fit-content">
          <RoleWrapper keyName="structureTemplateEditor">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Stack direction="row" alignItems="center">
                <Templates />
              </Stack>
            </Stack>
          </RoleWrapper>

          <Box>
            <RoleWrapper keyName="structureViewerAnnotator">
              <Card sx={{ padding: 2, marginBottom: 2 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography>
                    <b>Component</b>
                    <br />
                    {selectedComponent?.name}
                  </Typography>
                  <RoleWrapper keyName="structureTemplateEditor">
                    <IconButton
                      data-testid="add-pole-component"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAddNewComponentDialog(true);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </RoleWrapper>
                </Stack>
                <Stack spacing={1}>
                  {summaryContext.components
                    .sort((a, b) => a.id - b.id)
                    .map((component) => (
                      <PoleComponentItem
                        key={component.id}
                        onClick={() => {
                          setSelectedComponentID(
                            selectedComponentID === component.id
                              ? null
                              : component.id
                          );
                          scrollToPlacement();
                        }}
                        component={component}
                        selected={component.id === selectedComponentID}
                      />
                    ))}
                </Stack>
              </Card>
            </RoleWrapper>
            {!!selectedComponentID && (
              <Card sx={{ padding: 2, marginBottom: 2 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography sx={{ marginBottom: 1 }}>
                    <b>Placement</b>
                  </Typography>
                  <RoleWrapper keyName="structureTemplateEditor">
                    <IconButton
                      data-testid="add-pole-placement"
                      onClick={() => {
                        if (selectedComponentID) {
                          setAddNewPlacementDialog(true);
                        }
                      }}
                    >
                      <Add />
                    </IconButton>
                  </RoleWrapper>
                </Stack>
                <Stack justifyContent="space-between" flex={1} spacing={2}>
                  <Stack spacing={1}>
                    {selectedComponent
                      ? selectedComponent.placements
                          .sort((a, b) => a.id - b.id)
                          .map((placement) => (
                            <PolePlacementItem
                              key={placement.id}
                              placement={placement}
                              // @ts-ignore
                              componentID={selectedComponentID}
                              placementID={selectedPlacementID}
                              valueSystem={selectedComponent?.value_system_id}
                              onClick={(e) => {
                                placementClick(e, null, placement);
                              }}
                            />
                          ))
                      : null}
                  </Stack>
                </Stack>
              </Card>
            )}

            <Card sx={{ padding: 2, marginBottom: 2 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography>
                  <b>Structure Comment</b>
                </Typography>
              </Stack>
              <Stack justifyContent="space-between" flex={1} spacing={2}>
                <Stack spacing={1}>
                  <PoleComment />
                </Stack>
              </Stack>
            </Card>
          </Box>
        </Box>
        <AddPoleComponentPopup
          open={addNewComponentDialog}
          onClose={(value: number | null) => {
            setAddNewComponentDialog(false);
            if (!!value) {
              setSelectedComponentID(value);
            }
          }}
        />
        {!!selectedComponentID && (
          <AddComponentPlacementPopup
            open={addNewPlacementDialog}
            onClose={() => {
              setAddNewPlacementDialog(false);
            }}
            componentID={selectedComponentID}
          />
        )}
      </Box>
    </Paper>
  );
}
