import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Edit,
} from "@mui/icons-material";
import { Popover, Stack, Tooltip } from "@mui/material";
import { useDispatch, useLanguage, useSelector } from "hooks";
import { groupBy, mapValues, valuesIn } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import translations from "translations";
import { getClientObjectTypeName } from "utils/utils";
import { IImageAnnotation } from "views/AnnotationTool/api";
import { workflowStatuses } from "constants/imageReview";
import { updateAnnotationsTypeData } from "state/actions";
import { reviewModes } from "constants/imageReview";
import SetTypeSeverityDialog from "./SetTypeSeverityDialog";
import TextBar from "views/image/Review/ReviewTools/TextBar";
import {
  AnnotationToggleListWrapper,
  LoadingContainer,
  SelectedIndicator,
  ReviewMenuButtons,
  AnnotationName,
  AnnotationCircle,
  AnnotationBoxExpanded,
  SteelworkInfoTop,
  SteelworkInfoBottom,
} from "../components";
import { useFlags } from "launchdarkly-react-client-sdk";

interface OriginalAnnotation extends IImageAnnotation {
  categories: number;
}

interface IProps {
  // @ts-ignore
  containerRef?;
  image: number;
  // @ts-ignore
  setAnnotationListScroll: (boolean) => void;
  annotations: IImageAnnotation[];
  reviewMode: (typeof reviewModes)[keyof typeof reviewModes];
  selectedRegions: string[];
  speedZoom: (x: number, y: number, w: number, h: number, id: string) => void;
  toggleBox: (ids: string[], listToggle?: boolean) => void;
  renderAnnotationReviewButtons: (typeId: number) => JSX.Element;
  loading: boolean;
  onAnnotationTypeUpdate?: (args: {
    typeId: number;
    workflow_status?: number;
    type?: number;
    severity?: number;
  }) => void;
}

export default function AnnotationsList({
  loading,
  containerRef,
  image,
  setAnnotationListScroll,
  annotations,
  reviewMode,
  selectedRegions,
  speedZoom,
  toggleBox,
  renderAnnotationReviewButtons,
  onAnnotationTypeUpdate,
}: IProps) {
  const dispatch = useDispatch();
  const detectedCategories = useSelector(
    (state) => state.objects.detectedCategories
  );
  const annotatorObjectColor = useSelector(
    (state) => state.user.annotator_color
  );
  const issueCategories = useSelector((state) => state.objects.issueCategories);
  const objectTypes = useSelector((state) =>
    state.objects.objectTypes.filter(
      (t) => !t.skyqraft_only || state.user.skyqraft_employee
    )
  );
  const { imageViewerAnnotationListDefaultExpanded } = useFlags();
  const { language } = useLanguage();
  const [searchParams, setSearchParams] = useSearchParams();
  const [toggleChangeType, setToggleChangeType] = useState<{
    id: string;
    type: number | null;
  }>();
  const [originalAnnotation, setOriginalAnnotation] =
    useState<OriginalAnnotation>();

  const objectTypesWithClientName = objectTypes.map((e) =>
    getClientObjectTypeName(e.id)
  );
  const [previouseImageId, setPrevioseImageId] = useState<number | null>(null);

  const [getSettingSeverityForType, setSettingSeverityForType] = useState({
    type_id: null,
    image_id: null,
  });

  const [expandAnnotationsList, setExpandAnnotationsList] = useState(
    [
      reviewModes.MACHINE_OUTPUT,
      reviewModes.TRUE_POSITIVE_BBS,
      reviewModes.FALSE_POSITIVE_BBS,
    ].includes(reviewMode)
  );

  //Expand list by default feature flag
  useEffect(() => {
    if (imageViewerAnnotationListDefaultExpanded) {
      setExpandAnnotationsList(true);
    }
  }, [imageViewerAnnotationListDefaultExpanded]);

  useEffect(() => {
    //if the image is not the same as the previous image, then clear the BoxId from the url
    if (
      previouseImageId !== image &&
      previouseImageId !== null &&
      reviewMode === reviewModes.NONE &&
      !searchParams.has("poleStatus")
    ) {
      toggleBox([]);
    }

    if (previouseImageId === null) {
      setPrevioseImageId(image);
    }
  }, [image]);

  const modeOpen = reviewMode !== reviewModes.NONE;

  //Annotations
  // @ts-ignore
  let AnnotationsWithMultipleTypes = [];
  // @ts-ignore
  let AllAnnotations = [];

  if (objectTypesWithClientName.length > 0) {
    //Get only annotations with multiple types
    AnnotationsWithMultipleTypes = annotations
      .filter((f) => f.types.length > 1)
      .map((e) =>
        e.types.map((t, i) => {
          const objectType = objectTypesWithClientName.find((f) => f.id === t);

          if (
            objectType &&
            e.workflow_status[i] !== workflowStatuses.FALSE_POSITIVE
          ) {
            return {
              id: e.id,
              objectType: objectType,
            };
          }
        })
      );

    for (let i = 0; i < AnnotationsWithMultipleTypes.length; i++) {
      AnnotationsWithMultipleTypes[i] = AnnotationsWithMultipleTypes[i].filter(
        (e) => !!e
      );
    }

    AnnotationsWithMultipleTypes = AnnotationsWithMultipleTypes.filter(
      (e) => e.length > 0
    );

    //Get all annotations as a nested list.
    AllAnnotations = annotations
      .map((annotation) => {
        return annotation.types
          .filter(
            (t, i) =>
              objectTypesWithClientName.some((f) => f.id === t) &&
              (annotation.workflow_status[i] !==
                workflowStatuses.FALSE_POSITIVE ||
                reviewMode !== reviewModes.NONE)
          )
          .map((t, i) => {
            return {
              id: annotation.id,
              type: annotation.types[i],
              type_id: annotation.type_id[i],
              workflow_status: annotation.workflow_status[i],
              skyqraft_hidden: annotation.skyqraft_hidden[i],
              confidence: annotation.confidence[i],
              steelwork: annotation.steelwork,
              objectType: objectTypesWithClientName.filter(
                (f) => f.id === t
              )[0],
            };
          });
      })
      .filter((t) => t.length > 0);

    AllAnnotations.sort((a, b) => {
      const nameA = a[0].objectType.name.toUpperCase();
      const nameB = b[0].objectType.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  //Get annotations with one type and group them
  let filterOutOneTypes = annotations.filter(
    (f) =>
      f.types.length === 1 &&
      f.workflow_status[0] !== workflowStatuses.FALSE_POSITIVE
  );
  // @ts-ignore
  filterOutOneTypes = mapValues(filterOutOneTypes, (val) => {
    return {
      ...val,
      client_object_type_id: [
        getClientObjectTypeName(val.types[0])?.client_object_type_id,
      ],
    };
  });

  let groupedAnnotations = groupBy(
    filterOutOneTypes,
    "client_object_type_id[0]"
  );
  // @ts-ignore
  groupedAnnotations = mapValues(groupedAnnotations, (val) => {
    return {
      id: val.map((v) => v.id),
      length: val.length,
      objectType: objectTypesWithClientName.filter(
        (f) => f.id === val[0].types[0]
      )[0],
    };
  });
  // @ts-ignore
  groupedAnnotations = valuesIn(groupedAnnotations);
  // @ts-ignore
  groupedAnnotations = groupedAnnotations.filter((f) => !!f.objectType);

  //Popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  // @ts-ignore
  const handleClick = (event, id, type) => {
    setToggleChangeType({ id: id, type: type });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setToggleChangeType({ id: "", type: null });
    setAnchorEl(null);
    setOriginalAnnotation(undefined);
  };

  const open = Boolean(anchorEl);

  //Edit Type List stuff
  let sortedDetectedCategories = detectedCategories.sort((a, b) => {
    if (a.sorting_order < b.sorting_order) {
      return 1;
    }
    if (a.sorting_order > b.sorting_order) {
      return -1;
    }
    return 0;
  });

  let sortedIssueCategories = issueCategories.sort((a, b) => {
    if (a.sorting_order < b.sorting_order) {
      return 1;
    }
    if (a.sorting_order > b.sorting_order) {
      return -1;
    }
    return 0;
  });

  const detectedCatIds = sortedDetectedCategories.map((e) => e.id);
  const issueCatIds = sortedIssueCategories.map((e) => e.id);

  const groupedByDetectedCategory = groupBy(
    objectTypesWithClientName.filter(
      (ot) => ot.category_id && detectedCatIds.includes(ot.category_id)
    ),
    "category_id"
  );

  const groupedByIssueCategory = groupBy(
    objectTypesWithClientName.filter(
      (ot) => ot.category_id && issueCatIds.includes(ot.category_id)
    ),
    "category_id"
  );

  const groupedDetectedKeys = Object.keys(groupedByDetectedCategory).map((k) =>
    parseInt(k)
  );
  const groupedIssueKeys = Object.keys(groupedByIssueCategory).map((k) =>
    parseInt(k)
  );

  sortedDetectedCategories = sortedDetectedCategories.filter((e) =>
    groupedDetectedKeys.includes(e.id)
  );
  sortedIssueCategories = sortedIssueCategories.filter((e) =>
    groupedIssueKeys.includes(e.id)
  );
  // @ts-ignore
  const editTypeList = (sortedCat, t, groupedByCat, isIssue) => {
    const idString = String(t.id);
    const tempAnnotation = annotations.find((a) => a.id === idString);
    if (!tempAnnotation) {
      return <></>;
    }

    const index = tempAnnotation.type_id.findIndex(
      (type_id) => type_id === t.type_id
    );
    if (index === -1) {
      return <></>;
    }

    return (
      <div key={tempAnnotation.id} className="issueTypeContainer">
        <div className="issueCategoryContainer">
          {sortedCat
            // @ts-ignore
            .map((k) => k.id)
            // @ts-ignore
            .map((category) => {
              return (
                <div
                  key={`category-${category}`}
                  style={{
                    backgroundColor:
                      t.objectType.category_id === category ? "#FDD" : "",
                  }}
                  className="issueCategoryItem"
                  onClick={() => {
                    const tempAnnotation = annotations.filter(
                      (a) => a.id === t.id
                    )[0];

                    setOriginalAnnotation({
                      ...tempAnnotation,

                      categories: category,
                    });
                  }}
                >
                  <p>
                    {category
                      ? // @ts-ignore
                        sortedCat.find((item) => item.id === category)?.[
                          `${(language === "NO"
                            ? "EN"
                            : language
                          ).toLowerCase()}_name`
                        ]
                      : ""}
                  </p>
                </div>
              );
            })}
        </div>

        <div
          className="issueItemContainer"
          data-testid="annotationsList.editTypeList.issuesItemContainer"
        >
          {true &&
            Object.keys(groupedByCat)
              .filter((category) => {
                if (originalAnnotation?.categories) {
                  return parseInt(category) === originalAnnotation?.categories;
                } else {
                  return parseInt(category) === t.objectType.category_id;
                }
              })
              .map((category) => {
                return (
                  groupedByCat[category] // @ts-ignore
                    .sort((a, b) => {
                      const nameA = a.name.toUpperCase();

                      const nameB = b.name.toUpperCase();

                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      return 0;
                    })
                    // @ts-ignore
                    .map((obj, i) => (
                      <div
                        key={`${obj.id}`}
                        style={{
                          backgroundColor: t.type === obj.id ? "#FDD" : "",
                        }}
                        data-testid={`annotationsList.editTypeList.index.${obj.id}`}
                        className="issueTypeItem"
                        onClick={async () => {
                          let new_workflow_status =
                            tempAnnotation.workflow_status[index];

                          // TASK-624: If you change the type of a detected object, it should also be made verified.
                          if (!isIssue) {
                            new_workflow_status =
                              workflowStatuses.TRUE_POSITIVE;
                          }

                          await dispatch(
                            updateAnnotationsTypeData(
                              image,
                              t.type_id,
                              {
                                workflow_status: new_workflow_status,
                                type: obj.id,
                              },
                              () => {
                                // @ts-ignore
                                onAnnotationTypeUpdate({
                                  typeId: t.type_id,
                                  workflow_status: new_workflow_status,
                                  type: obj.id,
                                });
                              }
                            )
                          );
                          // Wait until the type has been updated before setting the severity
                          // Otherwise we can get race condition issues
                          if (isIssue) {
                            setSettingSeverityForType({
                              type_id: t.type_id, // @ts-ignore
                              image_id: image,
                            });
                          }
                          setToggleChangeType({ id: "", type: null });
                          setOriginalAnnotation(undefined);
                        }}
                      >
                        <p> {obj.name}</p>
                      </div>
                    ))
                );
              })}
        </div>
      </div>
    );
  };

  const [speedZoomToggleID, setSpeedZoomToggleID] = useState("");

  let speedZoomToggleList = [];
  // @ts-ignore
  function handleClickAnnotationBox(event, toogleValue, e) {
    if (event.shiftKey || event.ctrlKey) {
      let clickedBox: {
        x: number;
        y: number;
        w: number;
        h: number;
      };
      if (Array.isArray(e)) {
        clickedBox = annotations.filter((f) => f.id === e[0].id)[0];
        speedZoom(
          clickedBox.x,
          clickedBox.y,
          clickedBox.w,
          clickedBox.h,
          e[0].id
        );
      } else {
        speedZoomToggleList = e.id;
        const currentIndex = speedZoomToggleList.indexOf(speedZoomToggleID);

        if (currentIndex === -1) {
          clickedBox = annotations.filter((f) => f.id === e.id[0])[0];
          speedZoom(
            clickedBox.x,
            clickedBox.y,
            clickedBox.w,
            clickedBox.h,
            e.id[0]
          );
          setSpeedZoomToggleID(e.id[0]);
        }

        if (currentIndex !== -1 && currentIndex < e.id.length - 1) {
          clickedBox = annotations.filter(
            (f) => f.id === e.id[currentIndex + 1]
          )[0];
          speedZoom(
            clickedBox.x,
            clickedBox.y,
            clickedBox.w,
            clickedBox.h,
            e.id[currentIndex + 1]
          );
          setSpeedZoomToggleID(e.id[currentIndex + 1]);
        }

        if (currentIndex === e.id.length - 1) {
          clickedBox = annotations.filter(
            (f) => f.id === e.id[currentIndex]
          )[0];
          speedZoom(
            clickedBox.x,
            clickedBox.y,
            clickedBox.w,
            clickedBox.h,
            e.id[currentIndex]
          );
          setSpeedZoomToggleID("");
          speedZoomToggleList = [];
        }
      }
    } else {
      //If it's a single steelwork then we only want to select one at a time
      if (e.length === 1 && e[0]?.steelwork?.id) {
        toggleBox(toogleValue, false);
      } else {
        toggleBox(toogleValue, true);
      }
    }
  }

  const groupedAndCombinedAnnotations = [
    <>
      {!expandAnnotationsList &&
        AnnotationsWithMultipleTypes.length > 0 && // @ts-ignore
        AnnotationsWithMultipleTypes.map((e, i) => (
          <SelectedIndicator
            key={e[0].objectType.name + i}
            className="selectedIndicator"
            style={{
              backgroundColor: `${
                selectedRegions.includes(e[0].id) ? "#EFEFEF" : "#EFEFEF80"
              }`,
              pointerEvents: "all",
            }}
            onClick={(event) => handleClickAnnotationBox(event, [e[0].id], e)}
          >
            <Tooltip
              // @ts-ignore
              title={e.map((t, i) => (
                <div key={`${i}`}>{t.objectType.name}</div>
              ))}
              arrow
              placement="right"
              disableInteractive
            >
              <div className="annotationBox">
                {/* @ts-ignore */}
                {e.map((t, i) => (
                  <div
                    key={t.objectType.id}
                    className="annotationCircle"
                    style={{
                      backgroundColor: annotatorObjectColor
                        ? t.objectType?.class_color
                        : t.objectType?.color,
                      zIndex: 100 - i,
                    }}
                  />
                ))}
              </div>
            </Tooltip>
          </SelectedIndicator>
        ))}
    </>,
    <>
      {!expandAnnotationsList &&
        // @ts-ignore
        groupedAnnotations?.map((e, i) => (
          <Tooltip
            key={e.objectType.name + i}
            title={e.objectType.name}
            arrow
            placement="left"
            disableInteractive
          >
            <SelectedIndicator
              className="selectedIndicator"
              style={{
                backgroundColor: `${
                  selectedRegions.map((r) => e.id.includes(r)).includes(true)
                    ? "#EFEFEF"
                    : "#EFEFEF80"
                }`,
                pointerEvents: "all",
              }}
              onClick={(event) =>
                handleClickAnnotationBox(
                  event, // @ts-ignore
                  e.id.map((e) => e),
                  e
                )
              }
            >
              <div className="annotationBox">
                <div
                  className="annotationCircle"
                  style={{
                    backgroundColor: annotatorObjectColor
                      ? e.objectType?.class_color
                      : e.objectType?.color,
                    zIndex: 100 - i,
                  }}
                />
                {e.length > 1 && <p className="annotationCount">{e.length}</p>}
              </div>
            </SelectedIndicator>
          </Tooltip>
        ))}
    </>,
  ];

  const groupedAndCombinedAnnotationsSorted = groupedAndCombinedAnnotations
    .filter((e) => e.props.children.length > 0)
    .map((e) => e.props.children);
  groupedAndCombinedAnnotationsSorted.sort((a, b) => {
    const nameA = a?.key?.toUpperCase();
    const nameB = b?.key?.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const myDiv = useRef(null);

  function handleScroll() {
    // @ts-ignore
    if (myDiv.current.scrollHeight > myDiv.current.clientHeight) {
      setAnnotationListScroll(true);
    } else {
      setAnnotationListScroll(false);
    }
  }

  if (loading) {
    return (
      <LoadingContainer>
        <TextBar>Loading annotations...</TextBar>
      </LoadingContainer>
    );
  }
  if (!annotations.length) {
    return (
      <LoadingContainer>
        <TextBar>No annotations</TextBar>
      </LoadingContainer>
    );
  }

  return (
    <div
      style={{
        height: "80vh",
        position: "absolute",
        zIndex: "45",
        pointerEvents: "none",
      }}
      ref={containerRef}
    >
      {annotations.length > 0 && (
        <div className="arrowExpandButton">
          <Tooltip
            title={translations.ImageViewer.speedZoom[language]}
            arrow
            placement="top"
            disableInteractive
          >
            {!expandAnnotationsList ? (
              <ArrowForwardIosOutlined
                fontSize="small"
                sx={{ color: "#FFFFFF" }}
                onClick={() => {
                  setExpandAnnotationsList(true);
                }}
                style={{ pointerEvents: "all" }}
              />
            ) : (
              <ArrowBackIosOutlined
                fontSize="small"
                sx={{ color: "#FFFFFF" }}
                onClick={() => {
                  setExpandAnnotationsList(false);
                  setAnnotationListScroll(false);
                }}
                style={{ pointerEvents: "all" }}
              />
            )}
          </Tooltip>
        </div>
      )}
      <AnnotationToggleListWrapper
        ref={myDiv}
        className="annotationToggleListWrapper"
        onMouseOver={() => handleScroll()}
        onFocus={() => handleScroll()}
        onMouseLeave={() =>
          setTimeout(() => setAnnotationListScroll(false), 100)
        }
        style={{ pointerEvents: expandAnnotationsList ? "all" : "none" }}
      >
        {expandAnnotationsList &&
          AllAnnotations.length > 0 &&
          // @ts-ignore
          AllAnnotations.map((e, i) => (
            <SelectedIndicator
              key={`${i}`}
              className="selectedIndicator"
              expanded
              style={{
                border: "5px solid",
                borderColor: selectedRegions.includes(e[0]?.id)
                  ? "#1976d2"
                  : "#efefef00",
              }}
              onClick={(event) =>
                handleClickAnnotationBox(event, [e[0]?.id], e)
              }
            >
              <AnnotationBoxExpanded>
                {/* @ts-ignore */}
                {e.map((t, index) => {
                  return (
                    <>
                      <Stack
                        key={`${index}`}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        pr={1}
                        data-testid={`annotationsList.annotation.type_id.${t.type_id}`}
                      >
                        <Stack direction="row" spacing={1}>
                          {reviewMode !== reviewModes.MACHINE_OUTPUT && (
                            <AnnotationCircle
                              style={{
                                backgroundColor: annotatorObjectColor
                                  ? t.objectType?.class_color
                                  : t.objectType?.color,
                                zIndex: 100 - i,
                              }}
                            />
                          )}

                          <AnnotationName>{t.objectType.name}</AnnotationName>
                        </Stack>
                        {t?.steelwork && (
                          <SteelworkInfoTop steelwork={t.steelwork} />
                        )}
                      </Stack>
                      {selectedRegions.includes(e[0]?.id) && t?.steelwork && (
                        <SteelworkInfoBottom steelwork={t.steelwork} />
                      )}
                    </>
                  );
                })}
              </AnnotationBoxExpanded>

              {modeOpen && (
                <ReviewMenuButtons>
                  {/* @ts-ignore */}
                  {e.map((t, index) => (
                    <React.Fragment key={`${index}`}>
                      <div
                        key={`${index}`}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {reviewMode !== reviewModes.FALSE_POSITIVE_BBS && (
                          <Edit
                            onClick={(event) =>
                              handleClick(event, t.id, t.type)
                            }
                            style={{ marginRight: 5 }}
                            data-testid={`annotationsList.annotation.type_id.${t.type_id}.editType`}
                          />
                        )}
                        {renderAnnotationReviewButtons?.(t.type_id)}
                      </div>
                      <Popover
                        id={t.id}
                        open={
                          open &&
                          t.id === toggleChangeType?.id &&
                          t.type === toggleChangeType?.type
                        }
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        {t.id === toggleChangeType?.id &&
                        t.type === toggleChangeType?.type &&
                        !issueCatIds.includes(t.objectType.category_id)
                          ? editTypeList(
                              sortedDetectedCategories,
                              t,
                              groupedByDetectedCategory,
                              false
                            )
                          : editTypeList(
                              sortedIssueCategories,
                              t,
                              groupedByIssueCategory,
                              true
                            )}
                      </Popover>
                    </React.Fragment>
                  ))}
                </ReviewMenuButtons>
              )}
            </SelectedIndicator>
          ))}

        {groupedAndCombinedAnnotationsSorted}
      </AnnotationToggleListWrapper>
      {getSettingSeverityForType?.type_id && (
        <SetTypeSeverityDialog
          onClose={(severity) => {
            const { type_id, image_id } = getSettingSeverityForType;

            dispatch(
              updateAnnotationsTypeData(
                // @ts-ignore
                image_id,
                type_id,
                {
                  severity,
                },
                () => {
                  // @ts-ignore
                  onAnnotationTypeUpdate({
                    // @ts-ignore
                    typeId: type_id,
                    severity,
                  });
                }
              )
            );
            setSettingSeverityForType({
              type_id: null,
              image_id: null,
            });
          }}
        />
      )}
    </div>
  );
}
