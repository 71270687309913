import styled from "@emotion/styled";

interface SelectedIndicatorProps {
  expanded?: boolean;
}

export const SelectedIndicator = styled.div<SelectedIndicatorProps>`
	border-radius: 8px;
	display: flex;
	height: auto;
	background-color: #EFEFEF;
	align-items: center;
	// @ts-ignore
	height: ${(props) => (props.expanded ? "auto" : "34px")};
`;
