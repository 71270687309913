import { MenuItem, Typography, ListItemIcon } from "@mui/material";
import { Done, Clear } from "@mui/icons-material";
import { axiosInstance } from "utils/request";
import { updateMarkers } from "state/actions";
import { useCurrentProject, useDispatch } from "hooks";
import { toast } from "react-toastify";
import { EventHint, captureException } from "@sentry/react";

interface IProps {
  selection: google.maps.Rectangle;
}

function captureExceptionAndToast(errorMessage: string, hint: EventHint = {}) {
  toast.error(errorMessage);
  const completeHint: EventHint = {
    event_id: "map-menu.images-viewed",
    ...hint,
  };
  const error = new Error(errorMessage);
  captureException(error, completeHint);
}

export default function ImagesViewed({ selection }: IProps) {
  const currentProject = useCurrentProject();
  const dispatch = useDispatch();

  function updateDatabase(value: boolean) {
    if (!currentProject) {
      captureExceptionAndToast("No project loaded");
      return;
    }

    // Construct database post request
    if (!selection) {
      captureExceptionAndToast("No selection");
      return;
    }
    const bounds = selection.getBounds();
    if (!bounds) {
      captureExceptionAndToast("No bounds found");
      return;
    }
    const selectionBounds = bounds.toJSON();

    const body = {
      value,
      bbox: selectionBounds,
    };

    //Send data to databse
    axiosInstance
      .patch(`/project/${currentProject.id}/images/viewed/box`, body)
      .then(() => {
        // Update markers
        dispatch(updateMarkers());
      })
      .catch((error) => {
        captureExceptionAndToast("Failed to update", {
          originalException: error,
        });
      });
  }

  return (
    <MenuItem>
      <ListItemIcon
        onClick={() => {
          updateDatabase(true);
        }}
        style={{ minWidth: "auto", marginRight: "10px" }}
      >
        <Done />
      </ListItemIcon>
      <ListItemIcon
        onClick={() => {
          updateDatabase(false);
        }}
        style={{ minWidth: "auto", marginRight: "10px" }}
      >
        <Clear />
      </ListItemIcon>
      <Typography>Mark images as viewed</Typography>
    </MenuItem>
  );
}
