import React, { useState } from "react";
import { Stack, Slide } from "@mui/material";
import MenuItem from "components/MenuItem";
import translations from "translations";
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import { NavigationPopup, ListPopup, InformationPopup } from "./Popups";

import {
  ImageInfo,
  Lists,
  BoundingBox,
  Pipelines,
  GoogleMaps,
  DownloadImage,
  Thermal,
  Annotations,
  MachineReview,
  SupervisorReview,
  FalsePositiveReview,
  FlagImage,
  HideImage,
  FalseReview,
  FeedbackButton,
  OnlySelectedBoundingBox,
} from "./Buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "hooks";
import { RoleWrapper } from "components";
import { useFlags } from "launchdarkly-react-client-sdk";

type Props = {
  width: number;
};

export default function NormalController({ width }: Props) {
  const [menuExpanded, setMenuExpanded] = useState<boolean>(false);
  const { language } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  function toggleNavMenu() {
    setMenuExpanded(!menuExpanded);
  }
  const { onlyShowSelectedBbsFilterOption } = useFlags();

  const breakpointOne = 600;
  const breakpointTwo = 400;

  const menuOne = (
    <>
      <ImageInfo />
      <Lists />
      <BoundingBox />
      <Pipelines />
      <GoogleMaps />
      <DownloadImage />
      <RoleWrapper keyName="imageFeedbackList">
        {/* @ts-ignore */}
        <FeedbackButton />
      </RoleWrapper>
      {onlyShowSelectedBbsFilterOption && <OnlySelectedBoundingBox />}
    </>
  );

  const menuTwo = (
    <>
      <Thermal />
      <Annotations />
      <MachineReview />
      <SupervisorReview />
      <FalsePositiveReview />
      <FalseReview />
      {/* @ts-ignore */}
      <FlagImage />
      <HideImage />
    </>
  );

  return (
    <div id="imageViewerMenu">
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <Slide
          direction="up"
          mountOnEnter
          unmountOnExit
          in={width <= breakpointOne}
        >
          <div>
            <Stack direction="row">
              <MenuItem onClick={toggleNavMenu}>
                {menuExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </MenuItem>
            </Stack>
            <Slide direction="up" mountOnEnter unmountOnExit in={menuExpanded}>
              <div className="verticalMenu">
                {width <= breakpointOne && menuOne}
                {width <= breakpointTwo && menuTwo}
              </div>
            </Slide>
          </div>
        </Slide>
        <div style={{ overflow: "hidden" }}>
          <NavigationPopup />
          <ListPopup />
          <InformationPopup />

          <Stack direction="row" spacing={2} alignItems="flex-end">
            <Slide
              direction="up"
              mountOnEnter
              unmountOnExit
              in={width > breakpointOne}
            >
              <Stack direction="row">{menuOne}</Stack>
            </Slide>

            <Slide
              direction="up"
              mountOnEnter
              unmountOnExit
              in={width > breakpointTwo}
            >
              <Stack direction="row">{menuTwo}</Stack>
            </Slide>
            <div>
              <MenuItem
                tooltip={translations.ImageViewer.Close[language]}
                id="image-viewer-close"
                onClick={() => {
                  const searchParams = new URLSearchParams(location.search);
                  if (searchParams.has("pole")) {
                    searchParams.delete("pole");
                  }
                  if (searchParams.has("box")) {
                    searchParams.delete("box");
                  }
                  if (searchParams.has("addPoleItem")) {
                    searchParams.delete("addPoleItem");
                  }
                  const searchString = searchParams.toString();
                  if (searchString) {
                    navigate(`..?${searchString}`);
                  } else {
                    navigate("..");
                  }
                }}
              >
                <CloseIcon />
              </MenuItem>
            </div>
          </Stack>
        </div>
      </Stack>
    </div>
  );
}
