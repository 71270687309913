import { useSearchParams } from "react-router-dom";
import { useSelector } from "hooks";

const queryKey = "boxIdsOnly";

function getBboxIdsOnly(searchParams: URLSearchParams): boolean {
  const value = searchParams.get(queryKey);
  return value === "true";
}

function getFilterIds(searchParams: URLSearchParams): string[] {
  return searchParams.get("boxIds")?.split(",") || [];
}

export function useBboxIdsOnly() {
  const [searchParams, setSearchParams] = useSearchParams();
  const annotationsData = useSelector((state) => state.image.annotations);

  const active = getBboxIdsOnly(searchParams);

  const filterIds = active ? getFilterIds(searchParams) : [];

  const setBboxIdsOnlyFilter = (value: boolean) => {
    if (value) {
      searchParams.set(queryKey, "true");
    } else if (searchParams.has(queryKey)) {
      searchParams.delete(queryKey);
    }
    setSearchParams(searchParams, { replace: true });
  };

  const annotationsFiltered =
    filterIds.length > 0
      ? [...annotationsData].filter((a) => filterIds.includes(a.id))
      : annotationsData;
  const isFiltered =
    annotationsFiltered.length > 0 &&
    annotationsFiltered.length !== annotationsData.length &&
    active;
  const annotationsSelectedFilter = isFiltered
    ? annotationsFiltered
    : annotationsData;

  return {
    annotationsSelectedFilter,
    isFiltered,
    active,
    setBboxIdsOnlyFilter,
  };
}
