import React, { Fragment, memo } from "react";
import HighlightBox from "../HighlightBox";
import { Box } from "@mui/material";
import { asMutable } from "seamless-immutable";
import { isDetection } from "react-image-annotate/Annotator/reducers/general-reducer";
import { useLanguage } from "hooks";
import { useUserCanEditDetections } from "views/AnnotationTool/hooks/useUserCanEditDetections";

var boxCursorMap = [
  ["nw-resize", "n-resize", "ne-resize"],
  ["w-resize", "grab", "e-resize"],
  ["sw-resize", "s-resize", "se-resize"],
];

var arePropsEqual = function arePropsEqual(prev, next) {
  return (
    prev.region === next.region &&
    prev.dragWithPrimary === next.dragWithPrimary &&
    prev.createWithPrimary === next.createWithPrimary &&
    prev.zoomWithPrimary === next.zoomWithPrimary &&
    prev.mat === next.mat
  );
};

export const RegionSelectAndTransformBox = memo(function (_ref) {
  const canUpdateDetections = useUserCanEditDetections();
  const { language } = useLanguage();
  var r = _ref.region,
    mouseEvents = _ref.mouseEvents,
    projectRegionBox = _ref.projectRegionBox,
    dragWithPrimary = _ref.dragWithPrimary,
    createWithPrimary = _ref.createWithPrimary,
    zoomWithPrimary = _ref.zoomWithPrimary,
    onBeginMovePoint = _ref.onBeginMovePoint,
    onSelectRegion = _ref.onSelectRegion,
    setSelectedType = _ref.setSelectedType,
    objectTypes = _ref.objectTypes,
    mat = _ref.mat,
    user = _ref.user,
    onBeginBoxTransform = _ref.onBeginBoxTransform,
    showHighlightBox = _ref.showHighlightBox;
  var pbox = projectRegionBox(r);
  let boxes = [
    [0, 0],
    [0.5, 0],
    [1, 0],
    [1, 0.5],
    [1, 1],
    [0.5, 1],
    [0, 1],
    [0, 0.5],
    [0.5, 0.5],
  ];
  if (isDetection(r) && !canUpdateDetections) {
    boxes = [];
  }

  let shouldShow = !r.workflow_status.every((w) => w === 4);
  if (!shouldShow) {
    return null;
  }

  return React.createElement(
    Fragment,
    null,
    React.createElement(
      "div",
      null,
      showHighlightBox &&
        React.createElement(HighlightBox, {
          region: r,
          mouseEvents: mouseEvents,
          dragWithPrimary: dragWithPrimary,
          createWithPrimary: createWithPrimary,
          zoomWithPrimary: zoomWithPrimary,
          onBeginMovePoint: onBeginMovePoint,
          objectTypes: objectTypes,
          onSelectRegion: onSelectRegion,
          setSelectedType,
          pbox: pbox,
          language,
        }),
      dragWithPrimary &&
        !zoomWithPrimary &&
        !r.locked &&
        r.highlighted &&
        mat.a < 1.2 &&
        boxes.map(function (_ref2, i) {
          var [px, py] = _ref2;

          return React.createElement(
            Box,
            Object.assign(
              {
                key: i,
              },
              {
                sx: {
                  width: 8,
                  height: 8,
                  zIndex: 6,
                  border: "2px solid #fff",
                  position: "absolute",
                  cursor: zoomWithPrimary
                    ? "pointer"
                    : createWithPrimary
                      ? "crosshair"
                      : boxCursorMap[py * 2][px * 2],
                },
              },
              mouseEvents,
              {
                onMouseDown: function onMouseDown(e) {
                  if (e.button === 0)
                    return onBeginBoxTransform(r, [px * 2 - 1, py * 2 - 1]);
                  mouseEvents.onMouseDown(e);
                },
                style: {
                  left:
                    px === 0.5 && py === 0.5
                      ? pbox.x + 4
                      : pbox.x - 4 - 2 + pbox.w * px,
                  top:
                    px === 0.5 && py === 0.5
                      ? pbox.y + 4
                      : pbox.y - 4 - 2 + pbox.h * py,
                  width: px === 0.5 && py === 0.5 ? pbox.w - 8 : undefined,
                  height: px === 0.5 && py === 0.5 ? pbox.h - 8 : undefined,
                  borderWidth: px === 0.5 && py === 0.5 ? 0 : undefined,
                  borderRadius: px === 0.5 && py === 0.5 ? 4 : undefined,
                },
              }
            )
          );
        })
    )
  );
}, arePropsEqual);
export var RegionSelectAndTransformBoxes = memo(
  function (props) {
    return asMutable(props.regions)
      .filter(function (r) {
        return r.visible || r.visible === undefined;
      })
      .filter(function (r) {
        return !r.locked;
      })
      .sort(
        (ra, rb) => rb.w * rb.h - ra.w * ra.h || ra.categories - rb.categories
      )
      .map(function (r, i) {
        return React.createElement(
          RegionSelectAndTransformBox,
          Object.assign(
            {
              key: r.id,
            },
            props,
            {
              region: r,
            }
          )
        );
      });
  },
  function (n, p) {
    return n.regions === p.regions && n.mat === p.mat;
  }
);
export default RegionSelectAndTransformBoxes;
