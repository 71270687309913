import { getLidarURL } from "authentication/apiDetails";
import { setLidarWindow } from "state/actions";
import { RootState } from "state/reducers";
import store from "state/store";

interface LidarParams {
  imageId?: number;
  demoMode?: boolean;
  clearanceAxes?: number[];
  lat?: number;
  lng?: number;
  filter?: number;
  query?: string;
}

export function openLidarWindow(
  projectId: number,
  scene: string,
  params: LidarParams
) {
  const state: RootState = store.getState();
  const lidarWindow = state.imageViewer.lidarWindow;

  window.name = "sq-map";
  const LIDAR_URI = getLidarURL();
  if (lidarWindow === null || lidarWindow?.closed) {
    let searchParams = new URLSearchParams({
      scene: scene,
    });

    if (params.imageId) {
      searchParams.append("image", params.imageId.toString());
    }
    if (params.demoMode) {
      searchParams.append("demo", "true");
    }
    if (params.query) {
      searchParams.append("mapFilter", params.query);
    }
    if (params.clearanceAxes) {
      searchParams.append("clearanceAxes", params.clearanceAxes.toString());
    }
    if (params.lat) {
      searchParams.append("lat", params.lat.toString());
    }
    if (params.lng) {
      searchParams.append("lng", params.lng.toString());
    }
    if (params.filter) {
      searchParams.append("filter", params.filter.toString());
    }

    // No lidar window exists
    const URL = `${LIDAR_URI}/${projectId}?${searchParams.toString()}`;
    let lidarWindow = window.open(URL, "sq-lidar");
    store.dispatch(setLidarWindow(lidarWindow));
  } else {
    lidarWindow.postMessage(
      {
        project: projectId,
        scene,
        ...params,
      },
      LIDAR_URI
    );
    lidarWindow.focus();
  }
}
