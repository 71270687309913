import MenuItem from "components/MenuItem";

import { FilterAlt, FilterAltOff } from "@mui/icons-material";
import { Badge } from "@mui/material";

import { useBboxIdsOnly } from "hooks";

export default function OnlySelectedBoundingBox() {
  const { active, isFiltered, setBboxIdsOnlyFilter } = useBboxIdsOnly();

  return (
    <MenuItem
      tooltip={"Show only selected bounding boxes"}
      onClick={() => {
        setBboxIdsOnlyFilter(!active);
      }}
      id="show-bbox-ids-only"
    >
      {active ? (
        <Badge color="secondary" variant="dot" invisible={!isFiltered}>
          <FilterAlt />
        </Badge>
      ) : (
        <FilterAltOff />
      )}
    </MenuItem>
  );
}
