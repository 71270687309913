import React, { ReactElement, useState, useEffect } from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";
import {
  Delete,
  Warning as WarningIcon,
  Check,
  DoneAll,
  QuestionMark,
  Clear,
  Visibility,
  VisibilityOff,
  MotionPhotosOffOutlined,
  FlipToBack as FlipToBackIcon,
  RemoveDone,
  ThumbDown,
  Edit,
} from "@mui/icons-material";
import translations from "translations";
import SelectedItem from "./SelectedItem";
import ContextMenu from "./EditWorkflowContextMenu";
import RequestReview from "./EditWorkflowRequestReview";
import EditDefectType from "./SelectedItem/EditDefectType";
import EditObjectType from "./SelectedItem/EditObjectType";
import EditSeverity from "./SelectedItem/EditSeverity";
import { authorizedGet } from "utils/request";
import { useLanguage } from "hooks";
import { useUserCanEditDetections } from "views/AnnotationTool/hooks/useUserCanEditDetections";

interface Props {
  // @ts-ignore
  region;
  // @ts-ignore
  objectTypes;
  // @ts-ignore
  onChangeRegion;
  onDeleteRegion: Function;
  // @ts-ignore
  setSelectedType;
  // @ts-ignore
  setSavedType;
  // @ts-ignore
  savedRegions;
  // @ts-ignore
  onMLReviewChange;
  skyqraft_employee: boolean;
  supervisor: boolean;
  userId: number;
  // @ts-ignore
  user;
  // @ts-ignore
  issueCategories;
  // @ts-ignore
  detectedCategories;
  // @ts-ignore
  issueSeverities;
  // @ts-ignore
  annotatorObjectColor;
}

interface ObjectHistory {
  created?: boolean;
  origin: string;
  timestamp: string;
  human: boolean;
  changes?: {
    x?: {
      old: number;
      new: number;
    };
    y?: {
      old: number;
      new: number;
    };
    width?: {
      old: number;
      new: number;
    };
    height?: {
      old: number;
      new: number;
    };
    comment?: {
      old: string;
      new: string;
    };
  };
}

export default function ClassList({
  region,
  objectTypes,
  onChangeRegion,
  onDeleteRegion,
  setSelectedType,
  setSavedType,
  savedRegions,
  supervisor,
  user,
  issueCategories,
  detectedCategories,
  issueSeverities,
  annotatorObjectColor,
}: Props): ReactElement {
  const canUpdateDetections = useUserCanEditDetections();
  const { language } = useLanguage();
  const emptyPayload = {
    x: 0,
    y: 0,
    closeMenu: () => {},
    onChangeRegion: () => {},
    region: null,
    index: 0,
    supervisor: false,
  };

  const [stateComment, setStateComment] = useState(region.type_comment);
  // @ts-ignore
  const handleCommentChange = (e, index, region) => {
    const newComment = [...stateComment];
    newComment[index] = e.target.value;
    setStateComment(newComment);

    saveObject(
      {
        ...region,
        type_comment: newComment,
        isNew: false,
      },
      "severity"
    );
  };

  const [menuVisible, setVisibleMenu] = useState(false);
  const [menuPayload, setMenuPayload] = useState(emptyPayload);
  const [objectHistory, setObjectHistory] = useState<ObjectHistory[]>([]);

  useEffect(() => {
    if (!region.id.includes("n")) {
      authorizedGet<{ object: ObjectHistory[] }>(`/object/${region.id}/history`)
        .then((response) => {
          setObjectHistory(response.object);
        })
        .catch(() => {
          console.error("Fetching history failed");
          setObjectHistory([]);
        });
    }
  }, [region.id]);

  const deleteRegion = () => {
    onDeleteRegion(region);
  };
  // @ts-ignore
  const deleteItem = (index) => {
    if (region.types.length === 1) {
      deleteRegion();
    } else {
      onChangeRegion({
        ...region,
        // @ts-ignore
        types: region.types.filter((value, i) => i !== index),
        // @ts-ignore
        severities: region.severities.filter((value, i) => i !== index),
        // @ts-ignore
        is_defect: region.is_defect.filter((value, i) => i !== index),
        // @ts-ignore
        categories: region.categories.filter((value, i) => i !== index),
        // @ts-ignore
        processed: region.processed.filter((value, i) => i !== index),
        // @ts-ignore
        type_id: region.type_id.filter((value, i) => i !== index),
        workflow_status: region.workflow_status.filter(
          // @ts-ignore
          (value, i) => i !== index
        ),
        skyqraft_hidden: region.skyqraft_hidden.filter(
          // @ts-ignore
          (value, i) => i !== index
        ),
        editType: undefined,
        editIndex: undefined,
      });
    }
  };

  // @ts-ignore
  const convertToDefect = (index) => {
    onChangeRegion({
      ...region,
      // @ts-ignore
      types: region.types.map((value, i) => (i === index ? null : value)),
      // @ts-ignore
      severities: region.severities.map((value, i) =>
        i === index ? null : value
      ),
      // @ts-ignore
      categories: region.categories.map((value, i) =>
        i === index ? null : value
      ),
      // @ts-ignore
      is_defect: region.is_defect.map((value, i) =>
        i === index ? true : value
      ),
      // @ts-ignore
      workflow_status: region.workflow_status.map((value, i) =>
        i === index ? 2 : value
      ),
    });
  };

  // @ts-ignore
  const convertToDetection = (index) => {
    onChangeRegion({
      ...region,
      // @ts-ignore
      types: region.types.map((value, i) => (i === index ? null : value)),
      // @ts-ignore
      severities: region.severities.map((value, i) =>
        i === index ? null : value
      ),
      // @ts-ignore
      categories: region.categories.map((value, i) =>
        i === index ? null : value
      ),
      // @ts-ignore
      is_defect: region.is_defect.map((value, i) =>
        i === index ? false : value
      ),
      // @ts-ignore
      workflow_status: region.workflow_status.map((value, i) =>
        i === index ? 2 : value
      ),
    });
  };

  const statusMap = {
    1: <QuestionMark htmlColor="black" />,
    2: <Check htmlColor="black" />,
    3: <DoneAll htmlColor="black" />,
    4: <ThumbDown htmlColor="black" />,
    5: <RemoveDone htmlColor="black" />,
  };
  // @ts-ignore
  function contextMenuEvent(e, index) {
    if (e.type === "contextmenu") {
      e.preventDefault();
    }
    e.stopPropagation();
    setMenuPayload({
      x: e.pageX,
      y: e.pageY,
      closeMenu: () => {
        setVisibleMenu(false);
        setMenuPayload(emptyPayload);
      },
      onChangeRegion,
      region,
      index,
      supervisor,
    });
    setVisibleMenu(true);
  }
  // @ts-ignore
  const saveObject = (newRegion, variant) => {
    if (variant === "detection") {
      setSavedType(newRegion.types);
      setSelectedType(newRegion.types);
    } else if (variant === "defect") {
      setSelectedType(newRegion.types);
    }
    onChangeRegion(newRegion);
  };

  return (
    <div className="classList">
      {menuVisible && <ContextMenu menuPayload={menuPayload} user={user} />}
      {/* @ts-ignore */}
      {region.types.map((classification, index) => {
        let isHiddenBox = !region.visible;
        return (
          <div key={index}>
            <div
              className="classRow"
              key={index}
              onContextMenu={(e) => contextMenuEvent(e, index)}
            >
              <div className="order">
                <SelectedItem
                  index={index}
                  ml_class={region.workflow_status[index] === 4}
                  region={region}
                  savedRegions={savedRegions}
                  onChangeRegion={onChangeRegion}
                  setSelectedType={setSelectedType}
                  setSavedType={setSavedType}
                />
              </div>

              {canUpdateDetections &&
                !(region.workflow_status[index] === 4) &&
                (!!!region.is_defect[index] ? (
                  <Tooltip
                    disableInteractive
                    title={
                      translations.AnnotationTool.convertToDefect[language]
                    }
                    placement="top"
                  >
                    <IconButton
                      onClick={() => convertToDefect(index)}
                      style={{ padding: 2, marginTop: 5 }}
                    >
                      <WarningIcon htmlColor="black" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    disableInteractive
                    title={
                      translations.AnnotationTool.convertToDetection[language]
                    }
                    placement="top"
                  >
                    <IconButton
                      onClick={() => convertToDetection(index)}
                      style={{ padding: 2, marginTop: 5 }}
                    >
                      <FlipToBackIcon htmlColor="black" />
                    </IconButton>
                  </Tooltip>
                ))}

              {!(region.workflow_status[index] === 4) &&
                (canUpdateDetections || region.is_defect[index]) && (
                  <Tooltip
                    disableInteractive
                    title={translations.AnnotationTool.deleteClass[language]}
                    placement="top"
                  >
                    <IconButton
                      disabled={
                        !objectHistory[0]?.human &&
                        region.types.length < 2 &&
                        !region.id.includes("n")
                      }
                      onClick={() => deleteItem(index)}
                      style={{ padding: 2, marginTop: 5 }}
                    >
                      <Delete
                        htmlColor={
                          !objectHistory[0]?.human &&
                          region.types.length < 2 &&
                          !region.id.includes("n")
                            ? ""
                            : "black"
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}
              {canUpdateDetections &&
                (region.types.length > 1 ? (
                  <Tooltip
                    disableInteractive
                    title={translations.AnnotationTool.EditStatus[language]}
                    placement="top"
                  >
                    <IconButton
                      onClick={(e) => contextMenuEvent(e, index)}
                      style={{ padding: 2, marginTop: 5 }}
                    >
                      {/* @ts-ignore */}
                      {statusMap[region.workflow_status[index]]}
                      {region.skyqraft_hidden[index] && (
                        <MotionPhotosOffOutlined
                          style={{
                            position: "absolute",
                            width: 12,
                            height: 12,
                            top: 0,
                            right: 0,
                          }}
                          htmlColor="#333"
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                ) : (
                  <RequestReview
                    menuPayload={{ onChangeRegion, region, index, supervisor }}
                  />
                ))}

              <Tooltip
                disableInteractive
                title={translations.AnnotationTool.Visibility[language]}
                placement="right"
              >
                {/* @ts-ignore */}
                {isHiddenBox || region.workflow_status.every((w) => w === 4) ? (
                  <VisibilityOff
                    style={{ padding: 2, marginTop: 5 }}
                    className="visibleIcon"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onChangeRegion({
                        ...region,
                        visible: true,
                      });
                    }}
                  />
                ) : (
                  <Visibility
                    style={{ padding: 2, marginTop: 5 }}
                    className="visibleIcon"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onChangeRegion({
                        ...region,
                        visible: false,
                      });
                    }}
                  />
                )}
              </Tooltip>
            </div>
            <div className="typeOptions">
              <EditObjectType
                region={region}
                objectTypes={objectTypes}
                typeVisible={""}
                saveObject={saveObject}
                index={index}
                savedRegions={savedRegions}
                annotatorObjectColor={annotatorObjectColor}
              />

              <EditDefectType
                region={region}
                objectTypes={objectTypes}
                saveObject={saveObject}
                index={index}
              />

              <EditSeverity
                region={region}
                issueSeverities={issueSeverities}
                typeVisible={""}
                saveObject={saveObject}
                index={index}
              />
            </div>

            {!region.id.includes("n") ? (
              <>
                {!!region?.is_defect[index] ? (
                  <div className="commentSectionWrapper">
                    <div className="commentSectionIssue">
                      <div className="commentHeader">
                        <div className="headerTextFirst">
                          <Edit sx={{ height: "15px" }} />
                          <p>comment</p>
                        </div>
                      </div>
                      <div className="commentHeader">
                        <p className="headerText">created by</p>
                      </div>
                      <div className="commentHeader">
                        <p className="headerText">updated by</p>
                      </div>
                      <p className="commentText">
                        <textarea
                          className="commentInput"
                          placeholder={
                            translations.AnnotationTool.AddComment[language]
                          }
                          value={stateComment[index]}
                          onChange={(e) =>
                            handleCommentChange(e, index, region)
                          }
                        />
                      </p>
                      <p className="commentText">
                        {!!region?.creator[index]
                          ? region.creator[index]
                          : "you"}
                      </p>
                      <p className="commentTextLast">
                        {!!region?.creator[index]
                          ? region.updated_by[index]
                          : "you"}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="commentSectionWrapper">
                    <div className="commentSection">
                      <div className="commentHeader"></div>
                      <div className="commentHeader">
                        <p className="headerText">created by</p>
                      </div>
                      <div className="commentHeader">
                        <p className="headerText">updated by</p>
                      </div>
                      <p></p>
                      <p className="commentText">
                        {!!region?.creator[index]
                          ? region.creator[index]
                          : "you"}
                      </p>
                      <p className="commentTextLast">
                        {!!region?.creator[index]
                          ? region.updated_by[index]
                          : "you"}
                      </p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              !!region?.is_defect[index] && (
                <>
                  <div className="commentSectionWrapper">
                    <div className="commentSectionIssue">
                      <div className="commentHeader">
                        <div className="headerTextFirst">
                          <Edit sx={{ height: "15px" }} />
                          <p>comment</p>
                        </div>
                      </div>
                      <div className="commentHeader">
                        <p className="headerText">created by</p>
                      </div>
                      <div className="commentHeader">
                        <p className="headerText">updated by</p>
                      </div>
                      <p className="commentText">
                        <textarea
                          className="commentInput"
                          placeholder={
                            translations.AnnotationTool.AddComment[language]
                          }
                          value={stateComment[index]}
                          onChange={(e) =>
                            handleCommentChange(e, index, region)
                          }
                        />
                      </p>
                      <p className="commentText"></p>
                      <p className="commentTextLast"></p>
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        );
      })}
    </div>
  );
}
