import { getProjectStatistics } from "./api";
import { Box, Stack, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import type { AxisTickProps } from "@nivo/axes";
import { useLanguage, useSelector } from "hooks";
import translations from "./translations";
import { useMemo } from "react";
import { SortingOptions } from "./SortingButton";
import type { buttonMethodType } from "./SortingButton";
import { Loading } from "views";
import { extractProjectName } from "utils/utils";

type ProjectStatisticsType = Awaited<ReturnType<typeof getProjectStatistics>>;
const TextAreaWidth = 300;

type Props = {
  stats: ProjectStatisticsType;
  sortingMethod: buttonMethodType;
  loading: boolean;
};

export default function BarChart({ stats, sortingMethod, loading }: Props) {
  const { language } = useLanguage();
  const demoMode = useSelector((state) => state.ui.demoMode);

  // Prepare the data for the bar chart
  // we cannot add this directly to the BarChart component
  // since translations cannot be added to an object
  // in a clean way without typescript/biome complaints
  const dataPayload = useMemo(() => {
    const returnData = [];
    for (const stat of stats) {
      // Prepare the payload
      const payload: Record<string, string | number> = {};

      // Set the project name
      const { name, year } = extractProjectName(
        stat.project.name,
        language,
        stat.project.year,
        demoMode,
        true
      );
      payload[translations.Project[language]] = `${year} - ${name} `;

      // Set the process and unprocessed values using translations
      payload[translations.Unprocessed[language]] =
        stat.n_defects - stat.n_processed_defects;
      payload[translations.Processed[language]] = stat.n_processed_defects;

      // And push to the array
      returnData.push(payload);
    }
    return returnData;
  }, [stats, language, sortingMethod, demoMode]);

  // Prepare the order the datapoints are visualized in
  const keys = useMemo(() => {
    if (sortingMethod === SortingOptions.processed) {
      return [
        translations.Processed[language],
        translations.Unprocessed[language],
      ];
    }
    return [
      translations.Unprocessed[language],
      translations.Processed[language],
    ];
  }, [sortingMethod, language]);

  const CustomTick = (tick: AxisTickProps<number>) => (
    <g transform={`translate(${tick.x - TextAreaWidth},${tick.y})`}>
      <text
        textAnchor="start"
        dominantBaseline="middle"
        style={{
          fill: "#000",
          fontSize: 12,
        }}
      >
        {tick.value}
      </text>
    </g>
  );

  return (
    <Box
      sx={{
        height: stats.length * 25,
        flex: 1,
      }}
      mt={2}
    >
      {loading ? (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          mt={4}
        >
          <Loading relative color="black" size={50} />
          <Typography>Loading data...</Typography>
        </Stack>
      ) : (
        <ResponsiveBar
          data={dataPayload}
          indexBy={translations.Project[language]}
          keys={keys}
          layout="horizontal"
          groupMode="stacked"
          colors={({ id }) =>
            id === translations.Unprocessed[language] ? "#DE0100" : "#81ED16"
          }
          margin={{ left: TextAreaWidth, top: -5 }}
          axisLeft={{
            renderTick: CustomTick,
          }}
        />
      )}
    </Box>
  );
}
