import { useMemo } from "react";
import { Card, Grid, Stack, Typography } from "@mui/material";

import { getProjectStatistics } from "./api";
import { Loading } from "views";
import { useLanguage } from "hooks";
import translations from "./translations";

type ProjectStatisticsType = Awaited<ReturnType<typeof getProjectStatistics>>;
type Props = {
  stats: ProjectStatisticsType;
  loading: boolean;
};

const numberFormatter = new Intl.NumberFormat("sv-SE");

export default function Summary({ stats, loading }: Props) {
  const { language } = useLanguage();
  const totalDefects = useMemo(
    () => stats.reduce((acc, project) => acc + project.n_defects, 0),
    [stats]
  );

  const totalProcessedDefects = useMemo(
    () => stats.reduce((acc, project) => acc + project.n_processed_defects, 0),
    [stats]
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Card sx={{ p: 2, textAlign: "center" }}>
          <Stack spacing={2}>
            <Typography>{translations.TotalDefectsFound[language]}</Typography>
            {loading ? (
              <Loading color="black" relative size={20} />
            ) : (
              <Typography variant="h4">
                {numberFormatter.format(totalDefects)}
              </Typography>
            )}
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ p: 2, textAlign: "center" }}>
          <Stack spacing={2}>
            <Typography>
              {translations.TotalDefectsProcessed[language]}
            </Typography>
            {loading ? (
              <Loading color="black" relative size={20} />
            ) : (
              <Typography variant="h4">
                {numberFormatter.format(totalProcessedDefects)}
              </Typography>
            )}
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ p: 2, textAlign: "center" }}>
          <Stack spacing={2}>
            <Typography>{translations.UnprocessedDefects[language]}</Typography>
            {loading ? (
              <Loading color="black" relative size={20} />
            ) : (
              <Typography variant="h4">
                {numberFormatter.format(totalDefects - totalProcessedDefects)}
              </Typography>
            )}
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}
