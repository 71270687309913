import {
  MenuList,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
} from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import {
  DoneAll,
  ThumbDown,
  Done,
  MotionPhotosOffOutlined,
  RemoveDone,
} from "@mui/icons-material";
import { useUserCanEditDetections } from "views/AnnotationTool/hooks/useUserCanEditDetections";

type Props = {
  menuPayload: {
    onChangeRegion: Function;
    // @ts-ignore
    region;
    index: number;
    supervisor: boolean;
  };
  // @ts-ignore
  user;
};

export default function EditWorkflowContextMenu({ menuPayload, user }: Props) {
  const canEditDetections = useUserCanEditDetections();
  // @ts-ignore
  const setWorkflowStatus = (number) => {
    menuPayload.onChangeRegion({
      ...menuPayload.region,
      // @ts-ignore
      workflow_status: menuPayload.region.workflow_status.map((value, i) =>
        i === menuPayload.index ? number : value
      ),
    });
  };

  const toggleHidden = () => {
    menuPayload.onChangeRegion({
      ...menuPayload.region,
      // @ts-ignore
      skyqraft_hidden: menuPayload.region.skyqraft_hidden.map((value, i) =>
        i === menuPayload.index ? !value : value
      ),
      // @ts-ignore
      workflow_status: menuPayload.region.workflow_status.map((value, i) =>
        i === menuPayload.index && value === 1 ? 2 : value
      ),
    });
  };

  if (!canEditDetections) {
    return null;
  }

  return (
    <>
      <MenuList
        style={{
          display: "flex",
          flexDirection: "row",
          marginRight: "5px",
        }}
      >
        {/* @ts-ignore */}
        {!!!menuPayload.region.is_defect.every((a) => !a) && (
          <MenuItem
            style={{ padding: 5 }}
            onClick={() => toggleHidden()}
            disabled={!!menuPayload.region.processed[0]}
          >
            <MotionPhotosOffOutlined
              htmlColor={
                menuPayload.region.skyqraft_hidden[0] ? "orange" : undefined
              }
            />
          </MenuItem>
        )}
        <ToggleButtonGroup
          size="small"
          value={menuPayload.region.workflow_status[0]}
          exclusive
          sx={{ alignItems: "center" }}
        >
          <ToggleButton
            value={2}
            aria-label="module"
            sx={{ width: "40px", height: "40px" }}
          >
            <Done
              // @ts-ignore
              htmlColor={menuPayload.region.workflow_status[0] === 2 && "green"}
              // @ts-ignore
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (menuPayload.region.workflow_status[0] === 2) {
                  setWorkflowStatus(1);
                } else {
                  setWorkflowStatus(2);
                }
              }}
            />
          </ToggleButton>
          <RoleWrapper
            keyName="supervisorApprove"
            fallback={
              <ToggleButton
                value={3}
                aria-label="quilt"
                disabled={true}
                sx={{ width: "40px", height: "40px" }}
              >
                <DoneAll
                  // @ts-ignore
                  htmlColor={
                    menuPayload.region.workflow_status[0] === 3 && "green"
                  }
                />
              </ToggleButton>
            }
          >
            <ToggleButton
              value={3}
              aria-label="quilt"
              sx={{ width: "40px", height: "40px" }}
            >
              <DoneAll
                // @ts-ignore
                htmlColor={
                  menuPayload.region.workflow_status[0] === 3 && "green"
                }
                // @ts-ignore
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (menuPayload.region.workflow_status[0] === 3) {
                    setWorkflowStatus(1);
                  } else {
                    setWorkflowStatus(3);
                  }
                }}
              />
            </ToggleButton>
          </RoleWrapper>
        </ToggleButtonGroup>

        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

        <ToggleButtonGroup
          size="small"
          value={menuPayload.region.workflow_status[0]}
          exclusive
        >
          <ToggleButton
            value={4}
            aria-label="module"
            sx={{ width: "40px", height: "40px" }}
          >
            <ThumbDown
              // @ts-ignore
              htmlColor={menuPayload.region.workflow_status[0] === 4 && "red"}
              // @ts-ignore
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (menuPayload.region.workflow_status[0] === 4) {
                  setWorkflowStatus(1);
                } else {
                  setWorkflowStatus(4);
                }
              }}
            />
          </ToggleButton>

          <RoleWrapper
            keyName="supervisorApprove"
            fallback={
              <ToggleButton
                value={5}
                aria-label="quilt"
                disabled={true}
                sx={{ width: "40px", height: "40px" }}
              >
                <RemoveDone
                  // @ts-ignore
                  htmlColor={
                    menuPayload.region.workflow_status[0] === 5 && "red"
                  }
                />
              </ToggleButton>
            }
          >
            <ToggleButton
              value={5}
              aria-label="quilt"
              sx={{ width: "40px", height: "40px" }}
            >
              <RemoveDone
                // @ts-ignore
                htmlColor={menuPayload.region.workflow_status[0] === 5 && "red"}
                // @ts-ignore
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (menuPayload.region.workflow_status[0] === 5) {
                    setWorkflowStatus(1);
                  } else {
                    setWorkflowStatus(5);
                  }
                }}
              />
            </ToggleButton>
          </RoleWrapper>
        </ToggleButtonGroup>
      </MenuList>
    </>
  );
}
