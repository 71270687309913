import { validateRule } from "components/RoleHOC/wrapper";
import { useCurrentProject, useSelector } from "hooks";
import { useMemo } from "react";
import { RootState } from "state/reducers";

export function validateRuleAgainstState(state: RootState) {
  const { user, mission } = state;
  const {
    missions,
    accessRules,
    superuser,
    skyqraft_employee,
    accessLevelOverride,
  } = user;
  const currentProjectId = mission.id ?? 0;

  // Protect the validationRule checker against not-loaded data
  if (!missions?.length || !accessRules || !currentProjectId) {
    return false;
  }
  return validateRule({
    missions,
    keyName: "deleteNonDefects",
    accessRules,
    customerId: currentProjectId,
    superuser,
    skyqraft_employee,
    noOverride: false,
    accessLevelOverride: accessLevelOverride,
    treatAsMissionAgnostic: false,
  });
}

export function useUserCanEditDetections() {
  const currentProject = useCurrentProject();
  const {
    superuser,
    skyqraft_employee,
    missions,
    accessRules,
    accessLevelOverride,
  } = useSelector((state) => state.user);

  const canDeleteNonDefects = useMemo(() => {
    // Protect the validationRule checker against not-loaded data
    if (!missions?.length || !accessRules || !currentProject?.id) {
      return false;
    }
    return validateRule({
      missions,
      keyName: "deleteNonDefects",
      accessRules,
      customerId: currentProject.id,
      superuser,
      skyqraft_employee,
      noOverride: false,
      accessLevelOverride: accessLevelOverride,
      treatAsMissionAgnostic: false,
    });
  }, [
    missions,
    superuser,
    skyqraft_employee,
    currentProject,
    accessRules,
    accessLevelOverride,
  ]);

  return canDeleteNonDefects;
}
