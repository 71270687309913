import { ExpandMore } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

type Props = {
  open: boolean;
  children: React.ReactNode;
};

export default function ExpandMoreTitle({ open, children }: Props) {
  return (
    <Stack
      direction="row"
      spacing={4}
      alignItems="center"
      justifyContent="center"
      width="100%"
      py={1}
      sx={{
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      }}
    >
      <ExpandMore
        sx={{
          transform: open ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.3s",
        }}
      />
      <Typography>{children}</Typography>
      <ExpandMore
        sx={{
          transform: open ? "rotate(180deg)" : "rotate(360deg)",
          transition: "transform 0.3s",
        }}
      />
    </Stack>
  );
}
