import { AccessKeys, AccessLevel, AccessRules } from "interfaces";
import React from "react";
import { useSelector } from "react-redux";
import store from "state/store";

type RootState = ReturnType<typeof store.getState>;

interface IProps {
  children: React.ReactNode;
  keyName?: AccessKeys;
  multipleKeys?: AccessKeys[];
  missionAgnostic?: boolean;
  fallback?: React.ReactNode;
  noOverride?: boolean;
  treatAsMissionAgnostic?: boolean;
}

export function validateRule({
  missions,
  keyName,
  accessRules,
  customerId,
  superuser,
  skyqraft_employee,
  noOverride,
  accessLevelOverride,
  treatAsMissionAgnostic,
}: {
  missions: RootState["user"]["missions"];
  keyName: AccessKeys;
  accessRules: AccessRules;
  customerId: number;
  superuser: boolean;
  skyqraft_employee: boolean;
  noOverride: boolean;
  accessLevelOverride: AccessLevel | null;
  treatAsMissionAgnostic: boolean;
}): boolean {
  // Extract all missions
  const availableProjects = [...missions];

  // Use a list because one can have multiple ways to gain access to a mission
  let currentMissionList = [...availableProjects];
  // Extract the centralised key storage
  // @ts-ignore
  const recievedKeys = accessRules.rules[keyName].permissions;
  // @ts-ignore
  const missionAgnostic = accessRules.rules[keyName].missionAgnostic;

  // Check if we are looking at all missions
  if (!missionAgnostic && !treatAsMissionAgnostic) {
    currentMissionList = availableProjects.filter(
      (mission) => mission.id === customerId
    );
  }

  // If we have no missions, then we don't show anything
  let shouldRender = false;
  if (currentMissionList.length === 0) {
    shouldRender = false;
  }

  if (recievedKeys.length > 0) {
    shouldRender = false;
    for (const key of recievedKeys) {
      for (const mission of currentMissionList) {
        if (mission.access_level.includes(key)) {
          shouldRender = true;
        }
      }

      if (key === "superuser" && superuser) {
        shouldRender = true;
      }
      if (key === "skyqraft_employee" && skyqraft_employee) {
        shouldRender = true;
      }
    }
  }

  if (!noOverride && shouldRender && !!accessLevelOverride) {
    shouldRender = false;
    for (const key of recievedKeys) {
      shouldRender ||= key === accessLevelOverride;
    }
  }
  return shouldRender;
}

function WrapperComponent({
  noOverride,
  keyName,
  multipleKeys,
  children,
  fallback,
  treatAsMissionAgnostic,
}: IProps): JSX.Element {
  // Collect parameters from the state
  const user = useSelector((state: RootState) => state.user);
  const {
    superuser,
    skyqraft_employee,
    missions,
    accessRules,
    accessLevelOverride,
  } = user;
  const customerId = useSelector((state: RootState) => state.mission?.id ?? -1);

  // Check if we have the rules
  if (!accessRules?.rules) {
    // @ts-ignore
    return null;
  }

  let shouldRender = false;

  if (!keyName && !multipleKeys) {
    shouldRender = false;
  }

  if (keyName) {
    shouldRender = validateRule({
      missions,
      keyName,
      accessRules,
      customerId,
      superuser,
      skyqraft_employee,
      // @ts-ignore
      noOverride,
      // @ts-ignore
      accessLevelOverride,
      // @ts-ignore
      treatAsMissionAgnostic,
    });
  }

  if (multipleKeys) {
    for (const key of multipleKeys) {
      shouldRender ||= validateRule({
        missions,
        keyName: key,
        accessRules,
        customerId,
        superuser,
        skyqraft_employee,
        // @ts-ignore
        noOverride,
        // @ts-ignore
        accessLevelOverride,
        // @ts-ignore
        treatAsMissionAgnostic,
      });
    }
  }

  return <>{shouldRender ? children : fallback}</>;
}

export default WrapperComponent;
