import Select from "react-select";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { setMapType } from "state/actions";
import { useDispatch, useLanguage, useSelector } from "hooks";
import { MapTypes } from "constants/mapTypes";

const options = [
  { value: MapTypes.ROADMAP, label: "Roadmap" },
  { value: MapTypes.SATELLITE, label: "Satellite" },
  { value: MapTypes.HYBRID, label: "Hybrid" },
  { value: MapTypes.TERRAIN, label: "Terrain" },
];

function MapTypeSelector() {
  const dispatch = useDispatch();
  const mapType = useSelector((state) => state.map.type);
  const demoMode = useSelector((state) => state.ui.demoMode);
  const gmap = useSelector((state) => state.map.gmap);
  const { language } = useLanguage();

  return (
    <MenuItem onClick={() => {}} passive id="mapTypeSelector">
      <p>{translations.Menu.Settings.Map[language]}</p>
      <Select
        className="pr-10 pl-10 grow"
        name={"Map Type"}
        options={
          demoMode
            ? options.filter((o) => o.value === MapTypes.SATELLITE)
            : options
        }
        value={options.find((o) => o.value === mapType)}
        onChange={(e) => {
          if (e) {
            dispatch(setMapType(e.value));
            if (gmap) {
              gmap.setMapTypeId(e.value);
            }
          }
        }}
      />
    </MenuItem>
  );
}
export default MapTypeSelector;
