export default {
  VerifyAllObjects: {
    EN: "Verify all objects",
    SE: "Verifiera alla objekt",
    NO: "Verifiser alle objekter",
  },
  AllObjectsVerified: {
    EN: "All objects verified",
    SE: "Alla objekt är verifierade",
    NO: "Alle objekter er verifisert",
  },
};
