import { ReactElement, useEffect, useState } from "react";
import {
  Switch,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Stack,
} from "@mui/material";
import translations from "translations";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InfoDialogContent from "./InfoDialogContent";
import RoleWrapper from "components/RoleHOC/wrapper";
import { ImageType } from "interfaces";
import { ExpandMore } from "@mui/icons-material";
import { useLanguage, useSelector } from "hooks";
import "./style.scss";
import HeatmapToggle from "./HeatmapToggle";

type Props = {
  showMarkers: boolean;
  showPowerlines: boolean;
  showZones: boolean;
  setZoneVisibility: Function;
  setMarkerVisibility: Function;
  setPowerlineVisibility: Function;
  inspectorMode: boolean;
  showPoles: boolean;
  // @ts-ignore
  setPoleVisibility: (boolean) => void;
  skyqraftEmployee: boolean;
  imageTypes: ImageType[];
  setVisibleImageTypes: (image_types: number[]) => void;
  visibleTypes: number[];
  // @ts-ignore
  setAssignmentVisibility: (boolean) => void;
  showAssignments: boolean;
  assignmentsExist: boolean;
  showClearanceMarkers: boolean;
  setClearanceVisibility: (visibility: boolean) => void;
  showLidarMarkers: boolean;
  setLidarMarkerVisibility: (visibility: boolean) => void;
  updateMarkers: () => void;
};

export default function MapToggle({
  showPowerlines,
  showZones,
  setZoneVisibility,
  setPowerlineVisibility,
  showPoles,
  setPoleVisibility,
  skyqraftEmployee,
  imageTypes,
  setVisibleImageTypes,
  visibleTypes,
  setAssignmentVisibility,
  showAssignments,
  assignmentsExist,
  showClearanceMarkers,
  setClearanceVisibility,
  showLidarMarkers,
  setLidarMarkerVisibility,
  updateMarkers,
}: Props): ReactElement {
  const gmap = useSelector((state) => state.map.gmap);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const { language } = useLanguage();

  useEffect(() => {
    updateMarkers();
  }, [showZones, showClearanceMarkers, showLidarMarkers]);

  useEffect(() => {
    if (gmap) {
      // biome-ignore lint/complexity/noForEach: gmap.data is a google.maps.Data object
      gmap.data.forEach((feature) => {
        const isPowerline = feature.getProperty("powerline");
        if (isPowerline) {
          gmap.data.overrideStyle(feature, { visible: showPowerlines });
        } else {
          return;
        }
      });
    }
  }, [gmap, showPowerlines]);

  useEffect(() => {
    if (gmap) {
      // biome-ignore lint/complexity/noForEach: gmap.data is a google.maps.Data object
      gmap.data.forEach((feature) => {
        // @ts-ignore
        const type = feature.getGeometry().getType();
        const isClusterRadius = feature.getProperty("radius");
        if (type === "Polygon" && !isClusterRadius) {
          gmap.data.overrideStyle(feature, { visible: showZones });
        }
      });
    }
  }, [gmap, showZones]);

  return (
    <div id="maptoggle">
      <RoleWrapper keyName="advancedFilter">
        <HeatmapToggle />
      </RoleWrapper>
      <Accordion
        disableGutters
        sx={{ backgroundColor: "inherit", boxShadow: "none", m: 0, p: 0 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            flexDirection: "row-reverse",
          }}
        >
          <div style={{ flex: 1 }} />
          <FormControlLabel
            labelPlacement="start"
            id="imageToggle"
            control={<Switch />}
            checked={visibleTypes.length > 0}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (visibleTypes.length === 0) {
                // @ts-ignore
                setVisibleImageTypes([null, ...imageTypes.map((t) => t.id)]);
              } else {
                setVisibleImageTypes([]);
              }
            }}
            label={translations.Menu.Tools.ImageUpload.Images[language]}
          />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            pr: 2,
            py: 1,
            borderRadius: 1,
            backgroundColor: "rgba(255,255,255,0.5)",
          }}
        >
          <Stack>
            <FormControlLabel
              labelPlacement="start"
              control={<Switch />}
              // @ts-ignore
              checked={visibleTypes.includes(null)}
              onClick={() => {
                // @ts-ignore
                if (visibleTypes.includes(null)) {
                  setVisibleImageTypes(visibleTypes.filter((t) => t !== null));
                } else {
                  // @ts-ignore
                  setVisibleImageTypes([...visibleTypes, null]);
                }
              }}
              label={
                translations.Menu.Tools.MapObjectsToggle.NormalImage[language]
              }
            />
            {imageTypes.map((it) => (
              <FormControlLabel
                key={it.id}
                labelPlacement="start"
                control={<Switch />}
                checked={visibleTypes.includes(it.id)}
                onClick={() => {
                  if (visibleTypes.includes(it.id)) {
                    setVisibleImageTypes(
                      visibleTypes.filter((t) => t !== it.id)
                    );
                  } else {
                    setVisibleImageTypes([...visibleTypes, it.id]);
                  }
                }}
                label={language === "EN" ? it.name : it.se_name}
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <div
        className="row"
        onMouseUp={() => setPowerlineVisibility(!showPowerlines)}
        id="powerlineToggle"
      >
        <Typography>{translations.Menu.Tools.Powerlines[language]}</Typography>
        <Switch checked={showPowerlines} />
      </div>
      <Accordion
        disableGutters
        sx={{ backgroundColor: "inherit", boxShadow: "none", m: 0, p: 0 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            flexDirection: "row-reverse",
          }}
        >
          <div style={{ flex: 1 }} />
          <FormControlLabel
            labelPlacement="start"
            id="lidarToggle"
            control={<Switch />}
            checked={showZones || showClearanceMarkers || showLidarMarkers}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (showZones || showClearanceMarkers || showLidarMarkers) {
                setZoneVisibility(false);
                setClearanceVisibility(false);
                setLidarMarkerVisibility(false);
              } else {
                setZoneVisibility(true);
                setClearanceVisibility(true);
                setLidarMarkerVisibility(true);
              }
            }}
            label={"Lidar"}
          />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            pr: 2,
            py: 1,
            borderRadius: 1,
            backgroundColor: "rgba(255,255,255,0.5)",
          }}
        >
          <Stack>
            <FormControlLabel
              labelPlacement="start"
              control={<Switch />}
              checked={showZones}
              onClick={() => {
                setZoneVisibility(!showZones);
              }}
              label={translations.Menu.Tools.Powerlines.Zones[language]}
            />
            <RoleWrapper keyName="clearanceMarkers">
              <FormControlLabel
                labelPlacement="start"
                control={<Switch />}
                checked={showClearanceMarkers}
                onClick={() => {
                  setClearanceVisibility(!showClearanceMarkers);
                }}
                label={translations.ClearanceAnnotation.Markers[language]}
              />
            </RoleWrapper>
            <FormControlLabel
              labelPlacement="start"
              control={<Switch />}
              checked={showLidarMarkers}
              onClick={() => {
                setLidarMarkerVisibility(!showLidarMarkers);
              }}
              label={"Lidar Markers"}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <div
        className="row"
        onMouseUp={() => setPoleVisibility(!showPoles)}
        id="poleToggle"
      >
        <Typography>
          {translations.Menu.Tools.Powerlines.Poles[language]}
        </Typography>
        <Switch checked={showPoles} />
      </div>
      {assignmentsExist && (
        <div
          className="row"
          onMouseUp={() => setAssignmentVisibility(!showAssignments)}
          id="overlayToggle"
        >
          <Typography>Area Assignments</Typography>
          <Switch checked={showAssignments} />
        </div>
      )}
      <InfoDialogContent
        infoDialogOpen={infoDialogOpen}
        setInfoDialogOpen={setInfoDialogOpen}
      />
      <InfoOutlinedIcon
        id="infoToggle"
        onClick={() => setInfoDialogOpen(true)}
      />
    </div>
  );
}
