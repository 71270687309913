import { getIn, setIn } from "seamless-immutable";
import getActiveImage from "./get-active-image";
import { saveToHistory } from "./history-handler.js";
import colors from "../../colors";
import convertExpandingLineToPolygon from "./convert-expanding-line-to-polygon";
import { validateRuleAgainstState } from "views/AnnotationTool/hooks/useUserCanEditDetections";

var getRandomId = function getRandomId() {
  return Math.random().toString().split(".")[1];
};

export function isDetection(r) {
  return r.is_defect.every((i) => !i);
}

export default (function (state, action) {
  if (action.type === "ON_CLS_ADDED" && action.cls && action.cls !== "") {
    var oldRegionClsList = state.regionClsList;

    var newState = {
      ...state,
      regionClsList: oldRegionClsList.concat(action.cls),
    };

    return newState;
  } // Throttle certain actions

  if (action.type === "SELECT_REGION" && !!action?.location) {
    state = setIn(state, ["mouseDownLocation"], action.location);
  }

  if (action.type === "MOUSE_MOVE") {
    if (Date.now() - (state.lastMouseMoveCall || 0) < 16) return state;
    state = setIn(state, ["lastMouseMoveCall"], Date.now());
  }

  if (action.type === "DELETE_HIGHLIGHTED_REGIONS") {
    if (Date.now() - (state.deleteSelectedRegions || 0) < 100) return state;
    state = setIn(state, ["deleteSelectedRegions"], Date.now());
  }

  if (!action.type.includes("MOUSE")) {
    state = setIn(state, ["lastAction"], action);
  }

  var _getActiveImage = getActiveImage(state),
    currentImageIndex = _getActiveImage.currentImageIndex,
    activeImage = _getActiveImage.activeImage;

  var getRegionIndex = function getRegionIndex(region) {
    var regionId = typeof region === "string" ? region : region.id;
    var regionIndex = (state.regions || []).findIndex(function (r) {
      return r.id === regionId;
    });
    return regionIndex === -1 ? null : regionIndex;
  };

  var getRegion = function getRegion(regionId) {
    var regionIndex = getRegionIndex(regionId);
    if (regionIndex === null) return [null, null];
    var region = state.regions[regionIndex];
    return [region, regionIndex];
  };

  var modifyRegion = function modifyRegion(regionId, obj) {
    var [region, regionIndex] = getRegion(regionId);

    if (!region) return state;

    if (obj !== null) {
      return setIn(state, ["regions", regionIndex], { ...region, ...obj });
    } else {
      // delete region
      var regions = state.regions;
      return setIn(
        state,
        ["regions"],
        (regions || []).filter(function (r) {
          return r.id !== region.id;
        })
      );
    }
  };

  var closeEditors = function closeEditors(state) {
    if (currentImageIndex === null) return state;
    return setIn(
      state,
      ["regions"],
      (state.regions || []).map(function (r) {
        return { ...r, editingLabels: false };
      })
    );
  };

  var setNewImage = function setNewImage(img) {
    var _ref =
        typeof img === "object"
          ? img
          : {
              src: img,
            },
      src = _ref.src,
      frameTime = _ref.frameTime;

    return setIn(
      setIn(state, ["selectedImage"], src),
      ["selectedImageFrameTime"],
      frameTime
    );
  };

  switch (action.type) {
    case "@@INIT": {
      return state;
    }

    case "SELECT_IMAGE": {
      return setNewImage(action.image);
    }

    case "SET_LAST_USED_TYPE": {
      return setIn(state, ["lastObjectType"], action.lastObjectType[0]);
    }

    case "UPDATE_IMAGES": {
      state = setIn(state, ["images"], action.images);
      state = setNewImage(action.images[0]);
      state = setIn(state, ["lastSavedRegions"], action.images[0].regions);
      return state;
    }

    case "UPDATE_USER": {
      state = setIn(state, ["user"], action.user);
      return state;
    }

    case "DUPLICATE_REGION": {
      state = saveToHistory(state, "Create Box");
      const duplicateRegion = {
        ...action.region,
        id: "n" + getRandomId(),
        highlighted: true,
        editingLabels: true,
        cls: "New",
      };

      var _regions3 = Array.from(state.regions).concat(
        duplicateRegion ? [duplicateRegion] : []
      );

      return setIn(state, ["regions"], _regions3);
    }
    case "SAVE_REGIONS": {
      return setIn(state, ["lastSavedRegions"], state.regions);
    }
    case "SET_ISSUE_CATEGORIES": {
      return setIn(state, ["issueCategories"], action.issueCategories);
    }
    case "SET_OBJECT_TYPES": {
      return setIn(state, ["objectTypes"], action.objectTypes);
    }
    case "RESET_REGIONS": {
      //store.dispatch({ type: "SET_CAN_SWITCH_IMAGE", payload: true });
      state = setIn(state, ["regions"], state.lastSavedRegions);
      return setIn(state, ["allObjectHuman"], false);
    }
    case "SELECT_ALL": {
      let oldRegions = getIn(state, ["regions"]).map((r) => ({
        ...r,
        highlighted: true,
      }));
      return setIn(state, ["regions"], oldRegions);
    }

    case "COPY_REGION": {
      return setIn(
        state,
        ["copiedRegions"],
        state.regions.filter((r) => r.highlighted)
      );
    }

    case "PASTE_REGION": {
      if (!!state.copiedRegions) {
        let newRegions = getIn(state, ["copiedRegions"]).map((r) => ({
          ...r,
          id: "n" + getRandomId(),
          highlighted: true,
          x: r.x + 0.03,
          y: r.y + 0.03,
        }));
        let oldRegions = getIn(state, ["regions"]).map((r) => ({
          ...r,
          highlighted: false,
        }));
        newRegions = newRegions.filter(
          (r) => !(isDetection(r) && !validateRuleAgainstState(state))
        );

        state = setIn(state, ["regions"], [...oldRegions, ...newRegions]);
      }
      return state;
    }

    case "CHANGE_REGION": {
      var regionIndex = getRegionIndex(action.region);
      if (regionIndex === null) return state;
      if (state.mode?.mode !== "RESIZE_BOX") {
        state = setIn(state, ["mode"], null);
      }
      let region = action.region;
      // Insert relevant properties in the provided order
      return setIn(state, ["regions", regionIndex], region);
    }

    case "CHANGE_IMAGE": {
      var delta = action.delta;

      for (
        var _i = 0, _Object$keys = Object.keys(delta);
        _i < _Object$keys.length;
        _i++
      ) {
        var key = _Object$keys[_i];
        if (key === "cls") saveToHistory(state, "Change Image Class");
        if (key === "tags") saveToHistory(state, "Change Image Tags");
        state = setIn(state, [key], delta[key]);
      }

      return state;
    }
    case "DELETE_HIGHLIGHTED_REGIONS": {
      return setIn(
        state,
        ["regions"],
        (state.regions || []).filter(function (r) {
          if (isDetection(r) && !validateRuleAgainstState(state)) {
            return true;
          } else {
            return !r.highlighted;
          }
        })
      );
    }
    case "REVIEW_REGION": {
      let regionIndex = getRegionIndex(action.region);
      if (regionIndex === null) return state;

      // Structure data
      const workflow_map = {
        request: 1,
        approve: 2,
        supervisor_approve: 3,
        deny: 4,
      };

      const performUpdate = (state, target) => {
        let tempState = state;

        // Update workflow status
        tempState = setIn(
          tempState,
          [target, regionIndex, "workflow_status", action.index],
          workflow_map[action.action]
        );

        return tempState;
      };

      state = performUpdate(state, "regions");
      return state;
    }
    case "SELECT_REGION": {
      var region = action.region;

      var _regionIndex = getRegionIndex(action.region);

      if (_regionIndex === null) return state;
      if (action.location) {
        state = setIn(state, ["mode"], {
          mode: "SELECT_REGION",
          regionId: region.id,
          initialLocation: {
            x: region.x,
            y: region.y,
          },
        });
      } else {
        var { shiftIsPressed } = state;
        var regions = Array.from(state.regions || []);
        let region = state.regions[_regionIndex];
        if (shiftIsPressed) {
          regions = regions.map(function (r) {
            return {
              ...r,
              highlighted: r.highlighted
                ? r.id !== region.id
                : r.id === region.id,
              editingLabels: r.isNew,
              isNew: false,
            };
          });
        } else {
          regions = regions.map(function (r) {
            return {
              ...r,
              highlighted: r.id === region.id,
              editingLabels: r.isNew,
              isNew: false,
            };
          });
        }
        state = setIn(state, ["regions"], regions);
        return state;
      }

      // eslint-disable-next-line
      return state;
    }

    case "BEGIN_MOVE_POINT": {
      state = closeEditors(state);
      return setIn(state, ["mode"], {
        mode: "MOVE_REGION",
        regionId: action.point.id,
      });
    }

    case "BEGIN_BOX_TRANSFORM": {
      var box = action.box,
        directions = action.directions;
      state = closeEditors(state);

      if (directions[0] === 0 && directions[1] === 0) {
        return setIn(state, ["mode"], {
          mode: "MOVE_REGION",
          regionId: box.id,
          initialLocation: {
            x: box.x,
            y: box.y,
          },
        });
      } else {
        return setIn(state, ["mode"], {
          mode: "RESIZE_BOX",
          regionId: box.id,
          freedom: directions,
          original: {
            x: box.x,
            y: box.y,
            w: box.w,
            h: box.h,
          },
        });
      }
    }

    case "MOUSE_MOVE": {
      var x = action.x,
        y = action.y;
      if (!state.mode || !action.mouseIsDown) return state;

      switch (state.mode.mode) {
        case "MOVE_REGION": {
          var _regionId = state.mode.regionId;

          if (!!!state.mouseDownAt) {
            state = setIn(state, ["mouseDownAt"], { x, y });
          }
          var _regionIndex4 = getRegionIndex(_regionId);

          if (_regionIndex4 === null) return state;
          let region = state.regions[_regionIndex4];
          if (isDetection(region) && !validateRuleAgainstState(state)) {
            return state;
          }
          let newLocation = {
            x: region.x,
            y: region.y,
          };

          if (!!state.mouseDownAt) {
            let xDiff = x - state.mouseDownAt.x;
            let yDiff = y - state.mouseDownAt.y;
            newLocation = {
              x: state.mode.initialLocation.x + xDiff,
              y: state.mode.initialLocation.y + yDiff,
            };
          }

          return setIn(state, ["regions", _regionIndex4], {
            ...region,
            workflow_status: region.workflow_status.map((w) => Math.max(w, 2)),
            x: Math.min(Math.max(0, newLocation.x) + region.w, 1) - region.w,
            y: Math.min(Math.max(0, newLocation.y) + region.h, 1) - region.h,
          });
        }

        case "RESIZE_BOX": {
          var _state$mode2 = state.mode,
            _regionId2 = _state$mode2.regionId,
            xFree = _state$mode2.freedom[0],
            yFree = _state$mode2.freedom[1],
            _state$mode2$original = _state$mode2.original,
            ox = _state$mode2$original.x,
            oy = _state$mode2$original.y,
            ow = _state$mode2$original.w,
            oh = _state$mode2$original.h;

          var _regionIndex5 = getRegionIndex(_regionId2);
          let region = state.regions[_regionIndex5];
          if (isDetection(region) && !validateRuleAgainstState(state)) {
            return state;
          }

          if (_regionIndex5 === null) return state;
          var dx = xFree === 0 ? ox : xFree === -1 ? Math.min(ox + ow, x) : ox;
          var dw =
            xFree === 0
              ? ow
              : xFree === -1
                ? ow + (ox - dx)
                : Math.max(0, ow + (x - ox - ow));
          var dy = yFree === 0 ? oy : yFree === -1 ? Math.min(oy + oh, y) : oy;
          var dh =
            yFree === 0
              ? oh
              : yFree === -1
                ? oh + (oy - dy)
                : Math.max(0, oh + (y - oy - oh)); // determine if we should switch the freedom

          if (dw <= 0.001) {
            state = setIn(state, ["mode", "freedom"], [xFree * -1, yFree]);
            dw = 0.0004;
          }

          if (dh <= 0.001) {
            state = setIn(state, ["mode", "freedom"], [xFree, yFree * -1]);
            dh = 0.0004;
          }

          if (dx + dw > 1) {
            dw = 1 - dx;
          }

          if (dy + dh > 1) {
            dh = 1 - dy;
          }

          return setIn(state, ["regions", _regionIndex5], {
            ...region,
            workflow_status: region.workflow_status.map((w) => Math.max(w, 2)),
            x: dx,
            w: dw,
            y: dy,
            h: dh,
          });
        }

        case "DRAW_POLYGON": {
          var _regionId3 = state.mode.regionId;

          var [_region, _regionIndex6] = getRegion(_regionId3);

          if (!_region) return setIn(state, ["mode"], null);
          return setIn(
            state,
            ["regions", _regionIndex6, "points", _region.points.length - 1],
            [x, y]
          );
        }

        case "DRAW_EXPANDING_LINE": {
          var _regionId4 = state.mode.regionId;

          var [expandingLine, _regionIndex7] = getRegion(_regionId4);

          if (!expandingLine) return state;
          var isMouseDown = Boolean(state.mouseDownAt);

          if (isMouseDown) {
            // If the mouse is down, set width/angle
            var lastPoint = expandingLine.points.slice(-1)[0];
            var mouseDistFromLastPoint = Math.sqrt(
              Math.pow(lastPoint.x - x, 2) + Math.pow(lastPoint.y - y, 2)
            );
            if (mouseDistFromLastPoint < 0.002 && !lastPoint.width)
              return state;

            var _newState = setIn(
              state,
              ["regions", _regionIndex7, "points"],
              expandingLine.points.slice(0, -1).concat([
                {
                  ...lastPoint,
                  width: mouseDistFromLastPoint * 2,
                  angle: Math.atan2(lastPoint.x - x, lastPoint.y - y),
                },
              ])
            );

            return _newState;
          } else {
            // If mouse is up, move the next candidate point
            return setIn(state, ["regions", _regionIndex7], {
              ...expandingLine,
              candidatePoint: {
                x: x,
                y: y,
              },
            });
          }
        }

        case "SET_EXPANDING_LINE_WIDTH": {
          var _regionId5 = state.mode.regionId;

          var [_expandingLine, _regionIndex8] = getRegion(_regionId5);

          if (!_expandingLine) return state;

          var _lastPoint = _expandingLine.points.slice(-1)[0];

          var _state2 = state, // eslint-disable-next-line
            _mouseDownAt = _state2.mouseDownAt;
          return setIn(
            state,
            ["regions", _regionIndex8, "expandingWidth"],
            Math.sqrt(
              Math.pow(_lastPoint.x - x, 2) + Math.pow(_lastPoint.y - y, 2)
            )
          );
        }

        default:
          return state;
      }
    }

    case "MOUSE_DOWN": {
      var _x = action.x,
        _y = action.y;
      state = setIn(state, ["mouseDownAt"], {
        x: _x,
        y: _y,
      });

      if (state.allowedArea) {
        // TODO clamp x/y instead of giving up
        // TODO or image bounds
        var aa = state.allowedArea;

        if (_x < aa.x || _x > aa.x + aa.w || _y < aa.y || _y > aa.y + aa.h) {
          return state;
        }
      }

      if (state.mode) {
        switch (state.mode.mode) {
          case "DRAW_POLYGON": {
            var [_polygon2, _regionIndex9] = getRegion(state.mode.regionId);

            if (!_polygon2) break;
            return setIn(state, ["regions", _regionIndex9], {
              ..._polygon2,
              points: _polygon2.points.concat([[_x, _y]]),
            });
          }

          case "DRAW_EXPANDING_LINE": {
            var [_expandingLine2, _regionIndex10] = getRegion(
              state.mode.regionId
            );

            if (!_expandingLine2) break;

            var _lastPoint2 = _expandingLine2.points.slice(-1)[0];

            if (
              _expandingLine2.points.length > 1 &&
              Math.sqrt(
                Math.pow(_lastPoint2.x - _x, 2) +
                  Math.pow(_lastPoint2.y - _y, 2)
              ) < 0.002
            ) {
              if (!_lastPoint2.width) {
                return setIn(state, ["mode"], {
                  mode: "SET_EXPANDING_LINE_WIDTH",
                  regionId: state.mode.regionId,
                });
              } else {
                return state
                  .setIn(
                    ["regions", _regionIndex10],
                    convertExpandingLineToPolygon(_expandingLine2)
                  )
                  .setIn(["mode"], null);
              }
            } // Create new point

            return setIn(
              state,
              ["regions", _regionIndex10, "points"],
              _expandingLine2.points.concat([
                {
                  x: _x,
                  y: _y,
                  angle: null,
                  width: null,
                },
              ])
            );
          }

          case "SET_EXPANDING_LINE_WIDTH": {
            var [_expandingLine3, _regionIndex11] = getRegion(
              state.mode.regionId
            );

            if (!_expandingLine3) break;
            var expandingWidth = _expandingLine3.expandingWidth;
            return state
              .setIn(
                ["regions", _regionIndex11],
                convertExpandingLineToPolygon({
                  ..._expandingLine3,
                  points: _expandingLine3.points.map(function (p) {
                    return p.width ? p : { ...p, width: expandingWidth };
                  }),
                  expandingWidth: undefined,
                })
              )
              .setIn(["mode"], null);
          }

          default:
            break;
        }
      }

      var newRegion;
      var defaultRegionCls = undefined,
        defaultRegionColor = "#FF0000";
      if (activeImage && (state.regions || []).length > 0) {
        defaultRegionCls = state.regions.slice(-1)[0].cls;

        var _clsIndex = (state.regionClsList || []).indexOf(defaultRegionCls);

        if (_clsIndex !== -1) {
          defaultRegionColor = colors[_clsIndex % colors.length];
        }
      }
      switch (state.selectedTool) {
        case "create-point": {
          state = saveToHistory(state, "Create Point");
          newRegion = {
            type: "point",
            x: _x,
            y: _y,
            highlighted: true,
            editingLabels: true,
            color: defaultRegionColor,
            id: getRandomId(),
            cls: defaultRegionCls,
          };
          break;
        }

        case "defect-box": {
          state = saveToHistory(state, "Add Defect");
          newRegion = {
            type: "box",
            x: _x,
            y: _y,
            w: 0.01,
            h: 0.01,
            severities: [1],
            categories: [1],
            types: [null],
            type_comment: [""],
            processed: [null],
            type_id: [null],
            workflow_status: [2],
            skyqraft_hidden: [false],
            objectHasNoDefect: false,
            is_defect: [true],
            locked: false,
            highlighted: true,
            editingLabels: false,
            visible: true,
            isCreatedNow: true,
            id: "n" + getRandomId(),
            properties: [],
          };
          state = setIn(state, ["mode"], {
            mode: "RESIZE_BOX",
            editLabelEditorAfter: true,
            regionId: newRegion.id,
            freedom: [1, 1],
            original: {
              x: _x,
              y: _y,
              w: newRegion.w,
              h: newRegion.h,
            },
          });
          break;
        }

        case "create-box": {
          state = saveToHistory(state, "Create Box");
          newRegion = {
            type: "box",
            x: _x,
            y: _y,
            w: 0.0,
            h: 0.0,
            types: [state.lastObjectType],
            type_comment: [""],
            processed: [null],
            type_id: [null],
            workflow_status: [2],
            skyqraft_hidden: [false],
            severities: [null],
            categories: [null],
            is_defect: [false],
            objectHasNoDefect: false,
            isNew: true,
            locked: false,
            highlighted: true,
            editingLabels: true,
            visible: true,
            id: "n" + getRandomId(),
            properties: [],
          };
          state = setIn(state, ["mode"], {
            mode: "RESIZE_BOX",
            editLabelEditorAfter: true,
            regionId: newRegion.id,
            freedom: [1, 1],
            original: {
              x: _x,
              y: _y,
              w: newRegion.w,
              h: newRegion.h,
            },
          });
          break;
        }

        case "create-defect": {
          state = saveToHistory(state, "Create Defect");
          newRegion = {
            type: "box",
            x: _x,
            y: _y,
            w: 0.01,
            h: 0.01,
            types: [null],
            type_comment: [""],
            processed: [null],
            type_id: [null],
            workflow_status: [2],
            severities: [null],
            skyqraft_hidden: [false],
            categories: [2],
            is_defect: [true],
            locked: false,
            highlighted: true,
            editingLabels: false,
            color: defaultRegionColor,
            objectHasNoDefect: false,
            supervisor_approved: [false],
            visible: true,
            isNew: true,
            isCreatedNow: true,
            id: "n" + getRandomId(),
            properties: [],
          };
          state = setIn(state, ["mode"], {
            mode: "RESIZE_BOX",
            editLabelEditorAfter: true,
            regionId: newRegion.id,
            freedom: [1, 1],
            original: {
              x: _x,
              y: _y,
              w: newRegion.w,
              h: newRegion.h,
            },
          });
          break;
        }

        case "create-polygon": {
          if (state.mode && state.mode.mode === "DRAW_POLYGON") break;
          state = saveToHistory(state, "Create Polygon");
          newRegion = {
            type: "polygon",
            points: [
              [_x, _y],
              [_x, _y],
            ],
            open: true,
            highlighted: true,
            color: defaultRegionColor,
            cls: defaultRegionCls,
            id: getRandomId(),
          };
          state = setIn(state, ["mode"], {
            mode: "DRAW_POLYGON",
            regionId: newRegion.id,
          });
          break;
        }

        case "create-expanding-line": {
          state = saveToHistory(state, "Create Expanding Line");
          newRegion = {
            type: "expanding-line",
            unfinished: true,
            points: [
              {
                x: _x,
                y: _y,
                angle: null,
                width: null,
              },
            ],
            open: true,
            highlighted: true,
            color: defaultRegionColor,
            cls: defaultRegionCls,
            id: getRandomId(),
          };
          state = setIn(state, ["mode"], {
            mode: "DRAW_EXPANDING_LINE",
            regionId: newRegion.id,
          });
          break;
        }

        default:
          break;
      }

      if (newRegion?.w < 0.01) {
        newRegion.w = 0.015;
      }

      if (newRegion?.h < 0.01) {
        newRegion.h = 0.015;
      }

      var _regions = Array.from(state.regions)
        .map(function (r) {
          return setIn(r, ["editingLabels"], false).setIn(
            ["highlighted"],
            false
          );
        })
        .concat(newRegion ? [newRegion] : []);

      return setIn(state, ["regions"], _regions);
    }

    case "MOUSE_UP": {
      var _x2 = action.x,
        _y2 = action.y;

      if (!state.mode) return state;

      if (state.mode.mode === "SELECT_REGION") {
        if (
          action.RESIZE_BOX.pageX !== state.mouseDownLocation?.x ||
          action.RESIZE_BOX.pageY !== state.mouseDownLocation?.y
        ) {
          var regions = Array.from(state.regions || []);
          regions = regions.map(function (r) {
            return {
              ...r,
              highlighted: false,
              editingLabels: false,
              isNew: false,
            };
          });

          state = setIn(state, ["regions"], regions);

          return setIn(state, ["mode"], null);
        } else {
          state = setIn(state, ["mode", "mode"], "MOVE_REGION");
          var { shiftIsPressed } = state;
          var regions = Array.from(state.regions || []);
          var regionID = getRegionIndex(state.mode.regionId);
          let region = state.regions[regionID];
          if (shiftIsPressed) {
            regions = regions.map(function (r) {
              return {
                ...r,
                highlighted: r.highlighted
                  ? r.id !== region.id
                  : r.id === region.id,
                editingLabels: r.isNew,
                isNew: false,
              };
            });
          } else {
            regions = regions.map(function (r) {
              return {
                ...r,
                highlighted: r.id === region.id,
                editingLabels: r.isNew,
                isNew: false,
              };
            });
          }
          state = setIn(state, ["regions"], regions);
          return state;
        }
      }

      state = setIn(state, ["mouseDownAt"], null);

      switch (state.mode.mode) {
        case "RESIZE_BOX": {
          state = setIn(state, ["selectedTool"], "select");
          if (state.mode.isNew) {
            if (
              Math.abs(state.mode.original.x - _x2) < 0.001 &&
              Math.abs(state.mode.original.y - _y2) < 0.001
            ) {
              return setIn(
                modifyRegion(state.mode.regionId, null),
                ["mode"],
                null
              );
            }
          }

          if (state.mode.editLabelEditorAfter) {
            return {
              ...modifyRegion(state.mode.regionId, {
                editingLabels: true,
                isNew: true,
              }),

              mode: null,
            };
          }
        }
        // eslint-disable-next-line
        case "MOVE_REGION": {
          return { ...state, mode: null };
        }

        default:
          return state;
      }
    }

    case "OPEN_REGION_EDITOR": {
      var _regionIndex13 = getRegionIndex(action.region);

      if (_regionIndex13 === null) return state;
      var newRegions = setIn(
        state.regions.map(function (r) {
          return { ...r, highlighted: false, editingLabels: false };
        }),
        [_regionIndex13],
        {
          ...(state.regions || [])[_regionIndex13],
          highlighted: true,
          editingLabels: true,
        }
      );
      return setIn(state, ["regions"], newRegions);
    }

    case "CLOSE_REGION_EDITOR": {
      var _regionIndex14 = getRegionIndex(action.region);

      if (_regionIndex14 === null) return state;
      return setIn(state, ["regions", _regionIndex14], {
        ...(state.regions || [])[_regionIndex14],
        editingLabels: false,
      });
    }

    case "DELETE_REGION": {
      var _regionIndex15 = getRegionIndex(action.region);
      if (isDetection(action.region) && !validateRuleAgainstState(state)) {
        return state;
      }
      if (_regionIndex15 === null) return state;
      return setIn(
        state,
        ["regions"],
        (state.regions || []).filter(function (r) {
          return r.id !== action.region.id;
        })
      );
    }

    case "HEADER_BUTTON_CLICKED": {
      var buttonName = action.buttonName.toLowerCase();

      switch (buttonName) {
        case "prev": {
          if (currentImageIndex === null) return state;
          if (currentImageIndex === 0) return state;
          return setNewImage(state.images[currentImageIndex - 1]);
        }

        case "next": {
          if (currentImageIndex === null) return state;
          if (currentImageIndex === state.images.length - 1) return state;
          return setNewImage(state.images[currentImageIndex + 1]);
        }

        case "clone": {
          if (currentImageIndex === null) return state;
          if (currentImageIndex === state.images.length - 1) return state;
          return setIn(
            setNewImage(state.images[currentImageIndex + 1]),
            ["images", currentImageIndex + 1, "regions"],
            state.regions
          );
        }

        case "settings": {
          return setIn(state, ["settingsOpen"], !state.settingsOpen);
        }

        case "help": {
          return state;
        }

        case "fullscreen": {
          return setIn(state, ["fullScreen"], true);
        }
        case "all verified": {
          return setIn(state, ["allObjectHuman"], false);
        }
        case "verify all": {
          const newRegions = state.regions.map((region) => {
            return {
              ...region,
              workflow_status: region.workflow_status.map((value) =>
                Math.max(value, 2)
              ),
            };
          });
          return setIn(state, ["regions"], newRegions);
        }

        case "exit fullscreen":
        case "window": {
          return setIn(state, ["fullScreen"], false);
        }

        case "hotkeys": {
          return state;
        }

        case "exit":
        case "done": {
          return state;
        }

        default:
          return state;
      }
    }
    case "SET_TYPING": {
      return setIn(state, ["isTyping"], action.typing);
    }
    case "SELECT_TOOL": {
      let selectedTool = action.selectedTool.toLowerCase();
      if (selectedTool === "detection") {
        state = setIn(state, ["selectedTool"], "create-box");
      }
      if (selectedTool === "defect") {
        state = setIn(state, ["selectedTool"], "create-defect");
      }

      if (selectedTool === "select") {
        state = setIn(state, ["selectedTool"], "select");
      }

      return state;
    }
    case "TOGGLE_SHIFT": {
      const isPressed = action.payload;
      return setIn(state, ["shiftIsPressed"], isPressed);
    }
    case "CANCEL": {
      var _state4 = state,
        mode = _state4.mode;
      state = setIn(state, ["selectedTool"], "select");
      if (mode) {
        switch (mode.mode) {
          case "DRAW_EXPANDING_LINE":
          case "SET_EXPANDING_LINE_WIDTH":
          case "DRAW_POLYGON": {
            var _regionId6 = mode.regionId;
            return modifyRegion(_regionId6, null);
          }

          case "MOVE_POLYGON_POINT":
          case "RESIZE_BOX":
          case "MOVE_REGION": {
            return setIn(state, ["mode"], null);
          }

          default:
            return state;
        }
      } // Close any open boxes

      var _regions2 = state.regions;

      if (
        _regions2 &&
        _regions2.some(function (r) {
          return r.editingLabels;
        })
      ) {
        return setIn(
          state,
          ["regions"],
          _regions2.map(function (r) {
            return { ...r, editingLabels: false };
          })
        );
      } else if (_regions2) {
        return setIn(
          state,
          ["regions"],
          _regions2.map(function (r) {
            return { ...r, highlighted: false };
          })
        );
      }

      break;
    }

    default:
      break;
  }

  return state;
});
