import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getFilterActors(
  searchParams: URLSearchParams
): FilterType["ACTORS"] {
  const actorsParams = searchParams.get(FilterKey.ACTORS);
  const actors: FilterType["ACTORS"] = [];
  if (actorsParams) {
    for (const actor of actorsParams.split(",")) {
      if (actor === "Arkion") {
        actors.push(actor);
      } else if (actor === "AI") {
        actors.push(actor);
      } else {
        actors.push(Number(actor));
      }
    }
  }
  return actors;
}

export function useActorsFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const actorsFilter = getFilterActors(searchParams);

  const setActorsFilter = (actorIDs: number[]) => {
    if (actorIDs.length === 0) {
      if (searchParams.has(FilterKey.ACTORS))
        searchParams.delete(FilterKey.ACTORS);
    } else {
      searchParams.set(FilterKey.ACTORS, actorIDs.join(","));
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { actorsFilter, setActorsFilter };
}
