import { Flag, ThumbUpAlt } from "@mui/icons-material";
import { useObjectTypeFilter } from "hooks";
import { reviewModes } from "constants/imageReview";
import ToolsContainer from "views/image/Review/ReviewTools/ToolsContainer";
import PrimaryAction from "views/image/Review/ReviewTools/PrimaryAction";
import IgnoreImage from "views/image/Review/ReviewTools/IgnoreImage/IgnoreImage";
import ToggleBBIcon from "views/image/Review/ReviewTools/SecondaryActions/ToggleBBIcon";
import CloseReviewToolIcon from "views/image/Review/ReviewTools/SecondaryActions/CloseReviewToolIcon";
import OpenInEditorIcon from "views/image/Review/ReviewTools/SecondaryActions/OpenInEditorIcon";

interface IProps {
  currentImage: number;
  currentIndex: number;
  navigateToNextImage: () => void;
  navigateToPrevImage: () => void;
  goToImageByIndex: (index: number) => void;
  imageIds: number[];
  approveAllAnnotations: () => void;
  flagImageForReannotation: () => void;
  loading: boolean;
  loadingAnnotations: boolean;
}
export default function TruePositiveBbReview(props: IProps) {
  const {
    currentImage,
    currentIndex,
    navigateToNextImage,
    navigateToPrevImage,
    goToImageByIndex,
    imageIds,
    approveAllAnnotations,
    flagImageForReannotation,
    loading,
    loadingAnnotations,
  } = props;

  const { objectTypeFilter } = useObjectTypeFilter();

  const primaryActions = (
    <>
      <PrimaryAction
        color="lightGreen"
        hotkey="q"
        tooltipText={"Approve objects"}
        onClick={approveAllAnnotations}
        icon={<ThumbUpAlt sx={{ color: "#ffff" }} />}
        data-testid="truePositiveBbReview.approveImage"
        disabled={loadingAnnotations}
      />
      <PrimaryAction
        color="yellow"
        hotkey="w"
        tooltipText="Flag image for reannotation"
        onClick={flagImageForReannotation}
        icon={<Flag sx={{ color: "#ffff" }} />}
        data-testid="truePositiveBbReview.flagImage"
        disabled={loadingAnnotations}
      />
      <IgnoreImage
        imageId={currentImage}
        reviewModeId={reviewModes.TRUE_POSITIVE_BBS}
        objectTypeIds={objectTypeFilter}
        onIgnore={navigateToNextImage}
      />
    </>
  );

  const secondaryActions = (
    <>
      <ToggleBBIcon />
      <OpenInEditorIcon />
    </>
  );

  return (
    <>
      <ToolsContainer
        loading={loading}
        currentIndex={currentIndex}
        navigateToNextImage={navigateToNextImage}
        navigateToPrevImage={navigateToPrevImage}
        goToImageByIndex={goToImageByIndex}
        imageIds={imageIds}
        primaryActions={primaryActions}
        secondaryActions={secondaryActions}
      />
      <CloseReviewToolIcon />
    </>
  );
}
