import {
  Done,
  MotionPhotosOffOutlined,
  Replay,
  ThumbDownAlt,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React from "react";
import ToolsContainer from "views/image/Review/ReviewTools/ToolsContainer";
import PrimaryAction from "views/image/Review/ReviewTools/PrimaryAction";
import ToggleBBIcon from "views/image/Review/ReviewTools/SecondaryActions/ToggleBBIcon";
import CloseReviewToolIcon from "views/image/Review/ReviewTools/SecondaryActions/CloseReviewToolIcon";
import OpenInEditorIcon from "views/image/Review/ReviewTools/SecondaryActions/OpenInEditorIcon";
import { annotationActions } from "./constants";
import OrderByDropDown from "./OrderByDropDown";

interface IProps {
  currentIndex: number;
  navigateToNextImage: () => void;
  navigateToPrevImage: () => void;
  goToImageByIndex: (index: number) => void;
  imageIds: number[];
  setOrderImagesBy: (order: string) => void;
  updateAllNonDefectAnnotations: (args: {
    action: (typeof annotationActions)[keyof typeof annotationActions];
  }) => void;
  refreshAnnotations: () => void;
  hasAnyDefect: boolean;
  loading: boolean;
  loadingAnnotations: boolean;
}
export default function MachineReview(props: IProps) {
  const {
    currentIndex,
    navigateToNextImage,
    navigateToPrevImage,
    goToImageByIndex,
    imageIds,
    setOrderImagesBy,
    updateAllNonDefectAnnotations,
    refreshAnnotations,
    hasAnyDefect,
    loading,
    loadingAnnotations,
  } = props;

  const primaryActions = (
    <>
      <PrimaryAction
        color="lightGreen"
        hotkey="q"
        tooltipText={hasAnyDefect ? "Unavailable for defects" : "Approve all"}
        disabled={hasAnyDefect || loadingAnnotations}
        onClick={() => {
          updateAllNonDefectAnnotations({
            action: annotationActions.APPROVE,
          });
        }}
        icon={<Done sx={{ color: "#ffff" }} />}
        data-testid="machineReview.approveImage"
      />
      <PrimaryAction
        color="yellow"
        hotkey="w"
        tooltipText={
          hasAnyDefect ? "Unavailable for defects" : "Approve but hide all"
        }
        disabled={hasAnyDefect || loadingAnnotations}
        onClick={() => {
          updateAllNonDefectAnnotations({
            action: annotationActions.APPROVE_HIDE,
          });
        }}
        icon={<MotionPhotosOffOutlined sx={{ color: "#ffff" }} />}
        data-testid="machineReview.approveHideImage"
      />
      <PrimaryAction
        color="red"
        hotkey="e"
        tooltipText={
          hasAnyDefect ? "Unavailable for defects" : "Set all as false positive"
        }
        disabled={hasAnyDefect || loadingAnnotations}
        onClick={() => {
          updateAllNonDefectAnnotations({
            action: annotationActions.DENY,
          });
        }}
        icon={<ThumbDownAlt sx={{ color: "#ffff" }} />}
        data-testid="machineReview.rejectImage"
      />
    </>
  );

  const secondaryActions = (
    <>
      <ToggleBBIcon />
      <Tooltip title="Refresh annotations with changes from database">
        <Replay sx={{ color: "#ffff" }} onClick={refreshAnnotations} />
      </Tooltip>
      <OpenInEditorIcon />
      <OrderByDropDown setSelectedEntry={setOrderImagesBy} />
    </>
  );

  return (
    <>
      <ToolsContainer
        loading={loading}
        currentIndex={currentIndex}
        navigateToNextImage={navigateToNextImage}
        navigateToPrevImage={navigateToPrevImage}
        goToImageByIndex={goToImageByIndex}
        imageIds={imageIds}
        primaryActions={primaryActions}
        secondaryActions={secondaryActions}
      />
      <CloseReviewToolIcon />
    </>
  );
}
