import { useState } from "react";
import { MenuItem, Typography, ListItemIcon } from "@mui/material";
import { Done, Clear } from "@mui/icons-material";
import { authorizedPost } from "utils/request";
import RoleWrapper from "components/RoleHOC/wrapper";
import store from "state/store";
import { updateMarkers } from "state/actions";
import { useParams } from "react-router-dom";
import { useFeedBayFilter } from "hooks/filter/search_parameters/useFeedBayFilter";
import { useFilterPlay } from "hooks/filter/useFilterPlay";

interface IProps {
  selection: google.maps.Rectangle;
}

const HIDE_CHECK_MSG =
  "Are you sure you want to hide the images in the selected area?" +
  "\nOnce hidden they will not be shown to any end user." +
  "\nPlease double check this is correct.";

const UNHIDE_CHECK_MSG =
  "Are you sure you want to show the images in the selected area?" +
  "\nAll previous changes in this area from you or other users in this area might be affected.";

export default function EditImageVisibility({ selection }: IProps) {
  const params = useParams();
  const [flightId, setFlightId] = useState<string>("");

  const { feedBayFilter } = useFeedBayFilter();
  const { filterActive } = useFilterPlay();
  // @ts-ignore
  function changeState(value) {
    const alertString = value ? HIDE_CHECK_MSG : UNHIDE_CHECK_MSG;
    window.confirm(alertString) && updateDatabase(value);
  }
  // @ts-ignore
  function updateDatabase(value) {
    // Construct database post request
    // @ts-ignore
    const selectionBounds = selection.getBounds().toJSON();

    const body = {
      hidden: value,
      cid: params.mission,
      fid: flightId,
      bbox: selectionBounds,
      ...(feedBayFilter.length && filterActive && { feedBays: feedBayFilter }),
    };

    //Send data to databse
    authorizedPost("/image/hidden/box", body).then(() => {
      // Use store.dispatch to avoid circular dependencies
      store.dispatch(
        // @ts-ignore
        updateMarkers()
      );
    });
  }
  // @ts-ignore
  function updateFID(e) {
    setFlightId(e.target.value);
  }
  return (
    <RoleWrapper keyName="imageVisibility">
      <MenuItem>
        <ListItemIcon
          onClick={() => {
            changeState(true);
          }}
          style={{ minWidth: "auto", marginRight: "10px" }}
        >
          <Done />
        </ListItemIcon>
        <ListItemIcon
          onClick={() => {
            changeState(false);
          }}
          style={{ minWidth: "auto", marginRight: "10px" }}
        >
          <Clear />
        </ListItemIcon>
        <Typography>Hide images</Typography>
        <input
          type={"text"}
          value={flightId}
          placeholder={"Flight ID"}
          style={{ width: 70, marginLeft: 5, marginRight: 5 }}
          onChange={updateFID}
        />
      </MenuItem>
    </RoleWrapper>
  );
}
