import { useEffect, useState } from "react";
import { getProjectStatistics } from "./api";

type ProjectStatisticsType = Awaited<ReturnType<typeof getProjectStatistics>>;

export function useProjectStatistics() {
  const [loading, setLoading] = useState<boolean>(false);
  const [projectStatistics, setProjectStatistics] =
    useState<ProjectStatisticsType>([]);

  async function updateProjectStatistics() {
    setLoading(true);
    const response = await getProjectStatistics();
    setProjectStatistics(response);
    setLoading(false);
  }

  useEffect(() => {
    updateProjectStatistics();
  }, []);

  return { projectStatistics, loading };
}
