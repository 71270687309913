import { CalendarToday } from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { ThumbDown } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import { useCurrentProject, useDispatch, useSelector } from "hooks";
import { IssueSeverity, ObjectType } from "interfaces";
import { updateAnnotations } from "state/actions";
import translations from "translations";
import { authorizedDelete, authorizedPut } from "utils/request";
import { getClientObjectTypeName, getClientSeverityName } from "utils/utils";
import { IImageAnnotation } from "views/AnnotationTool/api";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link } from "react-router-dom";

type Props = {
  annotation: IImageAnnotation;
  objectTypes: ObjectType[];
  severities: IssueSeverity[];
  fixableDefects: boolean;
  // @ts-ignore
  updateAnnotationsData: (newAnnotation, image) => void;
  image: number;
  // @ts-ignore
  setGetData: (payload) => void;
  processedPossible: boolean;
};

export default function InfoContent({
  annotation,
  objectTypes,
  severities,
  fixableDefects,
  updateAnnotationsData,
  setGetData,
  image,
  processedPossible,
}: Props) {
  const { steelworkAnnotationTool } = useFlags();
  const currentProject = useCurrentProject();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const skyqraftHiddenSetting = useSelector(
    (state) => state.user.skyqraft_hidden
  );
  const showDsoTso = useSelector((state) => state.user.show_dso_tso);
  const types = objectTypes.filter((ot) => annotation.types.includes(ot.id));
  const language = user.language;

  if (types.length === 0) {
    console.error("No type found. Something went wrong");
    return null;
  }

  const text = [];
  // @ts-ignore
  const skyqraft_only = [];
  // @ts-ignore
  const workflow_status = [];

  const issueObject =
    annotation.severities.filter((a) => !!a).length > 0 &&
    severities.find((issue) => annotation.severities.includes(issue.id));

  annotation.types.forEach((type, index) => {
    const ot = getClientObjectTypeName(type);

    let currentString = ot.name;
    skyqraft_only.push(ot.skyqraft_only);
    workflow_status.push(annotation.workflow_status[index]);
    if (annotation.skyqraft_hidden[index]) {
      currentString += ` (${translations.ImageViewer.Hidden[language]})`;
    }
    text.push(currentString);
  });

  if (annotation.ring_count) {
    text.push(
      `${annotation.ring_count} ${translations.ImageViewer.Discs[language]}`
    );
  }

  if (annotation.severities.filter((a) => !!a).length > 0) {
    text.push(
      // @ts-ignore
      `${translations.ImageViewer.Severity[language]}: ${issueObject.id} (${
        // @ts-ignore
        getClientSeverityName(issueObject.id).name
      })`
    );
  }

  const isDefect = annotation.severities.filter((a) => !!a).length > 0;
  const defectVisible = isDefect && fixableDefects;

  let reported: Date | boolean;
  if (annotation.reported) {
    reported = new Date(annotation.reported);
  } else {
    reported = false;
  }
  // @ts-ignore
  const defectIndexes = [];
  // @ts-ignore
  const defectTypes = [];
  annotation.severities.forEach((severity, index) => {
    if (severity) {
      defectIndexes.push(index);
      const ot = getClientObjectTypeName(annotation.types[index]);
      defectTypes.push(ot.name);
    }
  });

  let fixed: Date | boolean;
  if (annotation.fixed) {
    fixed = new Date(annotation.fixed);
  } else {
    fixed = false;
  }

  return (
    <Stack
      spacing={0.5}
      sx={{ p: 0.5 }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div>
        {annotation.types.map((type, i) => (
          <Stack key={type} direction="row" spacing={1} alignItems="center">
            <div
              style={{
                padding: "5px",
                margin: "5px 0 5px 0",
                borderRadius: "5px",
                borderColor: "#757575",
                width: "100%",
              }}
            >
              <Typography fontSize="small">
                {`${getClientObjectTypeName(type)?.name} ${
                  annotation.skyqraft_hidden[i]
                    ? `(${translations.ImageViewer.Hidden[language]})`
                    : ""
                } `}
              </Typography>
              <Typography fontSize="small">
                {!!annotation.ring_count &&
                  `${annotation.ring_count} ${translations.ImageViewer.Discs[language]}`}
              </Typography>
              <Typography fontSize="small">
                {!!annotation.severities[i] &&
                  annotation.severities[i] !== 11 &&
                  `${translations.ImageViewer.Severity[language]}: ${
                    getClientSeverityName(annotation.severities[i])?.name
                  }`}
              </Typography>
              <Typography fontSize="small">
                {!!annotation.type_comment[i] && (
                  <>
                    {`${translations.Landing.ImageFeedback.comment[language]}:`}
                    {parseComment(annotation.type_comment[i])}
                  </>
                )}
              </Typography>
            </div>
            {/* @ts-ignore */}
            {skyqraft_only[i] && workflow_status[i] === 1 ? (
              <>
                <RoleWrapper keyName="reportDefect">
                  <IconButton
                    size="small"
                    onClick={() => {
                      const newWorkflowStatus = annotation.workflow_status;
                      newWorkflowStatus[i] = 2;
                      updateAnnotationsData(
                        { ...annotation, workflow_status: newWorkflowStatus },
                        image
                      );
                    }}
                  >
                    <Done
                      htmlColor={
                        annotation.workflow_status[i] === 2 ? "green" : "white"
                      }
                    />
                  </IconButton>

                  <IconButton
                    size="small"
                    onClick={() => {
                      const newWorkflowStatus = annotation.workflow_status;
                      newWorkflowStatus[i] = 4;
                      updateAnnotationsData(
                        { ...annotation, workflow_status: newWorkflowStatus },
                        image
                      );
                    }}
                  >
                    <ThumbDown
                      htmlColor={
                        annotation.workflow_status[i] === 4 ? "red" : "white"
                      }
                    />
                  </IconButton>
                </RoleWrapper>
              </>
            ) : (
              annotation.severities[i] === 11 && // @ts-ignore
              workflow_status[i] === 1 && (
                <>
                  <RoleWrapper keyName="reportDefect">
                    <IconButton
                      size="small"
                      onClick={() => {
                        const newWorkflowStatus = annotation.workflow_status;
                        newWorkflowStatus[i] = 4;
                        updateAnnotationsData(
                          {
                            ...annotation,
                            workflow_status: newWorkflowStatus,
                          },
                          image
                        );
                      }}
                    >
                      <ThumbDown
                        htmlColor={
                          annotation.workflow_status[i] === 4 ? "red" : "white"
                        }
                      />
                    </IconButton>
                  </RoleWrapper>
                </>
              )
            )}
          </Stack>
        ))}
      </div>
      {((isDefect && annotation.severities.includes(1)) || defectVisible) && (
        <Divider light variant="middle" sx={{ borderColor: "white", p: 0.5 }} />
      )}
      {isDefect && annotation.severities.includes(1) && (
        <Stack direction="row" spacing={1} alignItems="center">
          <RoleWrapper keyName="reportDefect">
            <Button
              size="small"
              variant="outlined"
              sx={{ backgroundColor: "white", justifyContent: "start" }}
              fullWidth
              startIcon={<CalendarToday fontSize="small" />}
              onClick={() =>
                setGetData({
                  visible: true,
                  comment: "",
                  isProcessDefect: false,
                  // @ts-ignore
                  callback: (date) => {
                    if (date) {
                      authorizedPut(`/object/${annotation.id}/report`, {
                        reported: date,
                      }).then(() =>
                        dispatch(
                          updateAnnotations(
                            // @ts-ignore
                            currentProject.id,
                            image,
                            skyqraftHiddenSetting,
                            showDsoTso
                          )
                        )
                      );
                    } else {
                      authorizedDelete(`/object/${annotation.id}/report`).then(
                        () =>
                          dispatch(
                            updateAnnotations(
                              // @ts-ignore
                              currentProject.id,
                              image,
                              skyqraftHiddenSetting,
                              showDsoTso
                            )
                          )
                      );
                    }
                  },
                })
              }
            >
              <Typography variant="body1" fontSize="small">
                {reported
                  ? `${
                      translations.ImageViewer.DefectReported[language]
                    } ${reported.toDateString()}`
                  : translations.ImageViewer.DefectNotReported[language]}
              </Typography>
            </Button>
          </RoleWrapper>
        </Stack>
      )}
      {defectVisible && (
        <Stack direction="row" spacing={1} alignItems="center">
          <RoleWrapper keyName="fixDefect">
            <Button
              size="small"
              variant="outlined"
              sx={{ backgroundColor: "white", justifyContent: "start" }}
              fullWidth
              startIcon={<CalendarToday fontSize="small" />}
              onClick={() =>
                setGetData({
                  visible: true,
                  comment: "",
                  isProcessDefect: false,
                  // @ts-ignore
                  callback: (date) => {
                    if (date) {
                      authorizedPut(`/object/${annotation.id}/fix`, {
                        fixed: date,
                      }).then(() =>
                        dispatch(
                          updateAnnotations(
                            // @ts-ignore
                            currentProject.id,
                            image,
                            skyqraftHiddenSetting,
                            showDsoTso
                          )
                        )
                      );
                    } else {
                      authorizedDelete(`/object/${annotation.id}/fix`).then(
                        () =>
                          dispatch(
                            updateAnnotations(
                              // @ts-ignore
                              currentProject.id,
                              image,
                              skyqraftHiddenSetting,
                              showDsoTso
                            )
                          )
                      );
                    }
                  },
                })
              }
            >
              <Typography variant="body1" fontSize="small">
                {fixed
                  ? `${
                      translations.ImageViewer.DefectFixed[language]
                    } ${fixed.toDateString()}`
                  : translations.ImageViewer.DefectNotFixed[language]}
              </Typography>
            </Button>
          </RoleWrapper>
        </Stack>
      )}
      {processedPossible && defectIndexes.length > 0 && (
        <Stack>
          {/* @ts-ignore */}
          {defectIndexes.map((index, i) => (
            <>
              <Stack
                key={index}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    justifyContent: "start",
                  }}
                  fullWidth
                  startIcon={<CalendarToday fontSize="small" />}
                  onClick={() =>
                    setGetData({
                      visible: true,
                      comment: annotation.processed[index]?.comment,
                      isProcessDefect: true,
                      // @ts-ignore
                      callback: (date, comment) => {
                        //filter out html tags
                        const filteredComment = comment?.replace(
                          /<\/?[^>]+(>|$)/g,
                          ""
                        );
                        if (date) {
                          authorizedPut(
                            `/object/${annotation.id}/${annotation.types[index]}/processed`,
                            {
                              date,
                              comment: filteredComment,
                            }
                          ).then(() =>
                            dispatch(
                              updateAnnotations(
                                // @ts-ignore
                                currentProject.id,
                                image,
                                skyqraftHiddenSetting,
                                showDsoTso
                              )
                            )
                          );
                        } else {
                          authorizedDelete(
                            `/object/${annotation.id}/${annotation.types[index]}/processed`
                          ).then(() =>
                            dispatch(
                              updateAnnotations(
                                // @ts-ignore
                                currentProject.id,
                                image,
                                skyqraftHiddenSetting,
                                showDsoTso
                              )
                            )
                          );
                        }
                      },
                    })
                  }
                >
                  <Typography variant="body1" fontSize="small">
                    {annotation.processed[index]
                      ? `${translations.ImageViewer.Processed[language]}${
                          defectIndexes.length > 1 // @ts-ignore
                            ? ` ${translations.words.For[language]} ${defectTypes[index]}`
                            : ""
                        } ${new Date(
                          annotation.processed[index].time
                        ).toDateString()}`
                      : `${translations.ImageViewer.MarkAsProcessed[language]}${
                          defectIndexes.length > 1 // @ts-ignore
                            ? ` ${translations.words.For[language]} ${defectTypes[index]}`
                            : ""
                        }`}
                  </Typography>
                </Button>
              </Stack>
              {!!annotation.processed[index]?.comment && (
                <Box ml={5}>
                  <Typography variant="body1" fontSize="small">
                    {`${translations.DefectReport.Comment[language]}:`}
                    {parseComment(annotation.processed[index].comment)}
                  </Typography>
                </Box>
              )}
            </>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

function parseComment(comment: string) {
  const regex = /###(\d+)###/g;
  return (
    <>
      {comment.split(regex).map((part, index) =>
        index % 2 === 1 ? (
          <Link
            to={`../${part}?${window.location.search}`}
            style={{ color: "#0078EB" }}
          >
            {part}
          </Link>
        ) : (
          part
        )
      )}
    </>
  );
}
