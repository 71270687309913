import { MenuItem, Typography, ListItemIcon } from "@mui/material";
import { Image } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import store from "state/store";
import { authorizedGet } from "utils/request";
import { toast } from "react-toastify";
import translations from "translations";
import { useLanguage } from "hooks";

interface IProps {
  // @ts-ignore
  data;
}

type ClosestImageResponse = {
  id: number;
};

const fetchClosestImage = async (
  lat: number,
  lng: number
): Promise<ClosestImageResponse> => {
  try {
    const response = await authorizedGet(
      `/image/closest?lat=${lat}&lng=${lng}&r=200`
    );
    return response;
  } catch (error) {
    console.error("failed fetching closes images: ", error);
    return { id: 0 };
  }
};
// @ts-ignore
const loadClosestImage = async (data, navigate, location) => {
  const state = store.getState();
  const allowed = state.image.canSwitchImage;
  if (allowed || !window.location.pathname.includes("annotate")) {
    const { lat, lng } = data;
    const closest = await fetchClosestImage(lat, lng);

    if (closest.id) {
      navigate(`${closest.id}${location.search}`);
    } else {
      toast.warning("Unable to find nearby image");
    }
  } else {
    alert("You have unsaved changes");
  }
};
function ImageItem({ data }: IProps) {
  const { language } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <MenuItem
      onClick={() => {
        loadClosestImage(data, navigate, location);
      }}
    >
      <ListItemIcon>
        <Image />
      </ListItemIcon>
      <Typography>{translations.MapInfo.ShowImage[language]}</Typography>
    </MenuItem>
  );
}

export default ImageItem;
