import { Info } from "@mui/icons-material";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ImageMeta } from "interfaces";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isFilterActive } from "utils/filter";
import { authorizedGet } from "utils/request";
import { HighlightMarkers, unHighlightMarkers } from "utils/utils";
import { sortImages } from "utils/utils";
import MapMarker from "views/map/marker";
import LazyLoadImage from "../LazyLoadImage";
import { useLanguage, useSelector, useDispatch } from "hooks";
import "./style.css";
import { setSelectedPreviousDefectImage } from "state/actions";
import { Link } from "react-router-dom";
import translations from "translations";
import FlowDirectionArrow from "components/FlowDirectionArrow";
import { useFlags } from "launchdarkly-react-client-sdk";

const DEFAULT_WIDTH = 133;
const ROTATION_OFFSET = 180;

export interface IThumbnail {
  id: number;
  flight_id: number;
  compass_dir: number;
  image_type?: {
    id: number;
    name: string;
  };
  weight: number;
  src: string;
}

interface IProps {
  canLoad: boolean;
  image: ImageMeta | null;
  customerId: number;
  // @ts-ignore
  objectTypes;
  bbox: boolean;
  skyqraft_employee: boolean;
  clusterHidden: boolean;
  clusterMethod: string;
  distance: string;
  annotatorObjectColor: boolean;
  openedMarkers: number[];
  thumbnailRegexPattern: string | null;
  setThumbnailRegexPattern: (pattern: string | null) => void;
  imageSortingMethod: "compass" | "timestamp";
  thumbnailPoleItemPlacement: number | null;
  setThumbnailPoleItemPlacement: (position: number | null) => void;
}

export default function PreviewSlider({
  image,
  canLoad,
  customerId,
  objectTypes,
  bbox,
  skyqraft_employee,
  clusterHidden,
  clusterMethod,
  distance,
  annotatorObjectColor,
  openedMarkers,
  thumbnailRegexPattern,
  setThumbnailRegexPattern,
  imageSortingMethod,
  thumbnailPoleItemPlacement,
  setThumbnailPoleItemPlacement,
}: IProps) {
  // Collect data from the URL
  const navigate = useNavigate();
  const { language } = useLanguage();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const flow_angle = useSelector((state) => state.image?.current?.flow_angle);
  const [menuImageId, setMenuImageId] = useState<number | null>(null);
  const open = Boolean(anchorEl);
  // @ts-ignore
  const imageID = parseInt(params.image);
  const placementID = searchParams.get("placementID");
  const componentID = searchParams.get("componentID");
  const poleID = searchParams.get("pole");

  const dispatch = useDispatch();
  const { stuctureClusterThumbnailImages } = useFlags();

  const selectedPreviousDefectImage = useSelector(
    (state) => state.imageViewer.selectedPreviousDefectImage
  );

  // Check if we are filtering out less severe images
  const severitys = searchParams.get("severity");
  const filterActive = !!(severitys?.includes("3") || severitys?.includes("4"));

  // Find the thumbnails for the current image
  // and cluster method.
  const [thumbnails, setThumbnails] = useState<IThumbnail[]>([]);
  const [sortedThumbnails, setSortedThumbnails] = useState<IThumbnail[]>([]);

  useEffect(() => {
    if (image && !clusterHidden) {
      const searchParamsNew = new URLSearchParams({
        distance: distance,
        filteractive: filterActive ? "true" : "false",
        ...(thumbnailRegexPattern && { thumbnailRegexPattern }),
        ...(componentID && { componentID }),
        ...(placementID && { placementID }),
        ...(poleID && { pole: poleID }),
      });

      const searchString = searchParamsNew.toString();
      let clusterType = thumbnailPoleItemPlacement
        ? "structure"
        : clusterMethod;

      //This is for the feature flag to get the structure cluster images
      clusterType = stuctureClusterThumbnailImages
        ? clusterType
        : clusterMethod;

      authorizedGet<{ images: IThumbnail[] }>(
        `/image/${imageID}/${clusterType}?${searchString}`
      )
        .then(({ images }) => {
          setThumbnails(images);
        })
        .catch(() => {
          console.log("No Cluster found");
        });
    }
  }, [
    image,
    setThumbnails,
    customerId,
    skyqraft_employee,
    clusterMethod,
    distance,
    filterActive,
    thumbnailRegexPattern,
    thumbnailPoleItemPlacement,
    clusterHidden,
    placementID,
    componentID,
    searchParams,
  ]);

  useEffect(() => {
    if (thumbnails.length > 0) {
      const sorted = sortImages(thumbnails, ROTATION_OFFSET);
      setSortedThumbnails(sorted);
    }
    if (thumbnails.length === 0 && thumbnailPoleItemPlacement) {
      setSortedThumbnails([]);
    }
  }, [imageSortingMethod, thumbnails]);

  const sliderRef = useRef(null);
  const trackRef = useRef(null);
  const [mouseIsDown, setMouseDown] = useState(false);
  const [oldX, setOldX] = useState(0);
  const [oldY, setOldY] = useState(0);
  const [pressTime, setPressTime] = useState(0);

  // @ts-ignore
  function moveTrack(x) {
    const slider = sliderRef.current;
    // @ts-ignore
    slider.scrollBy(-x, 0);
  }

  useEffect(() => {
    // @ts-ignore
    function updateScroll(e) {
      if (trackRef?.current && (mouseIsDown || e.type === "wheel")) {
        if (sliderRef?.current && trackRef && trackRef.current) {
          moveTrack(e.type === "wheel" ? 0.3 * e.wheelDelta : e.movementX);
        }
      }
    }
    // @ts-ignore
    function setMouse(e) {
      if (e.button === 0 && e.type === "mousedown") {
        setMouseDown(true);
        setOldX(e.pageX);
        setOldY(e.pageY);
        setPressTime(new Date().getTime());
      } else {
        setMouseDown(false);
      }
    }
    // @ts-ignore
    function handleKeyDown(e) {
      const activeElement = document.activeElement;

      if (
        activeElement instanceof HTMLInputElement ||
        activeElement instanceof HTMLTextAreaElement
      ) {
        return;
      }

      if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
        const currentIndex = sortedThumbnails.findIndex(
          (thumbnail) => thumbnail.id === imageID
        );
        if (currentIndex !== -1) {
          const increment = e.key === "ArrowLeft" ? -1 : 1;
          const newIndex = currentIndex + increment;
          if (newIndex >= 0 && newIndex < sortedThumbnails.length) {
            const targetImageId = sortedThumbnails[newIndex].id;
            showImage(targetImageId);
          }
        }
      }
    }

    if (sliderRef?.current) {
      const slider = sliderRef.current;
      // @ts-ignore
      slider.addEventListener("mousemove", updateScroll);
      // @ts-ignore
      slider.addEventListener("wheel", updateScroll);
      // @ts-ignore
      slider.addEventListener("mousedown", setMouse);
      // @ts-ignore
      slider.addEventListener("mouseup", setMouse);
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        // @ts-ignore
        slider.removeEventListener("mousemove", updateScroll);
        // @ts-ignore
        slider.removeEventListener("wheel", updateScroll);
        // @ts-ignore
        slider.removeEventListener("mousedown", setMouse);
        // @ts-ignore
        slider.removeEventListener("mouseup", setMouse);
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  });
  // @ts-ignore
  const showImage = (id) => {
    navigate(`../${id}${window.location.search}`);
  };
  // @ts-ignore
  const moveToImage = (node) => {
    node.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  useEffect(() => {
    const marker = document.querySelector(
      `.clusterPreviewImage .marker.image-${imageID}`
    );
    if (marker?.parentNode) {
      moveToImage(marker.parentNode);
    } else {
      setTimeout(() => {
        const marker = document.querySelector(
          `.clusterPreviewImage .marker.image-${imageID}`
        );
        marker?.parentNode && moveToImage(marker.parentNode);
      }, 1000);
    }
  }, [image]);
  // @ts-ignore
  const handleClick = (e) => {
    if (e.ctrlKey) {
      moveToImage(e.target);
    } else {
      dispatch(setSelectedPreviousDefectImage(null));
    }
  };

  return (
    <div
      id="sliderContainer"
      className="sliderContainer"
      ref={sliderRef}
      onMouseLeave={() => setMouseDown(false)}
    >
      <div
        className="sliderTrack"
        ref={trackRef}
        onDragStart={(e) => e.preventDefault()}
        style={{
          transition: mouseIsDown
            ? "height 500ms"
            : "height 500ms, margin-left 500ms",
          height: clusterHidden ? 0 : 125,
        }}
      >
        {!clusterHidden &&
          sortedThumbnails.map((clusterImage, i) => {
            return (
              <Link
                style={{ position: "relative" }}
                to={`/${customerId}/${clusterImage.id}${window.location.search}`}
                key={`preview-image-${clusterImage.id}`}
                className={`clusterPreviewImage ${imageID} ${
                  clusterImage?.id === imageID && !selectedPreviousDefectImage
                    ? "imageOverlay"
                    : ""
                }`}
                onContextMenu={(e) => {
                  setAnchorEl(e.currentTarget);
                  setMenuImageId(clusterImage.id);
                }}
                onMouseEnter={() => {
                  if (!!image && !!image.pole_id) {
                    // @ts-ignore
                    HighlightMarkers(clusterImage?.id, null, image.pole_id);
                  }
                }}
                onMouseLeave={() => {
                  if (!!image && !!image.id) {
                    // @ts-ignore
                    unHighlightMarkers(clusterImage?.id, null, image.pole_id);
                  }
                }}
                onClick={(e) => {
                  const distance = Math.sqrt(
                    (oldX - e.pageX) * (oldX - e.pageX) +
                      (oldY - e.pageY) * (oldY - e.pageY)
                  );
                  if (
                    distance < 10 &&
                    new Date().getTime() - pressTime < 1000
                  ) {
                    handleClick(e);
                  } else {
                    e.preventDefault();
                  }
                }}
              >
                <LazyLoadImage
                  onLoad={() => {}}
                  onClick={() => {}}
                  defaultWidth={DEFAULT_WIDTH}
                  alt="A preview"
                  image={clusterImage?.id}
                  skyqraft_employee={skyqraft_employee}
                  src={clusterImage.src}
                  canLoad={canLoad}
                  objectTypes={objectTypes}
                  bbox={bbox}
                  annotatorObjectColor={annotatorObjectColor}
                />
                <Box position="absolute" top={2} right={18}>
                  <MapMarker
                    id={clusterImage?.id}
                    weight={clusterImage.weight}
                    viewed={0}
                    is_filtered={true}
                    pole_id={null}
                    cluster_id={null}
                    compass_dir={clusterImage.compass_dir}
                    // @ts-ignore
                    missionId={params.mission}
                    image_type={clusterImage.image_type?.id ?? 0}
                    lat={0}
                    lng={0}
                    isPreview={true}
                    filterActive={isFilterActive(searchParams)}
                    openedMarkers={openedMarkers}
                  />
                </Box>
                {flow_angle && (
                  <FlowDirectionArrow
                    angle={flow_angle - clusterImage.compass_dir}
                  />
                )}
              </Link>
            );
          })}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open && !!menuImageId}
        onClose={() => {
          setAnchorEl(null);
          setMenuImageId(null);
        }}
      >
        <MenuItem
          onClick={() => {
            const link = `/${customerId}/${menuImageId}${window.location.search}`;
            window.open(link, "_blank");
            setAnchorEl(null);
            setMenuImageId(null);
          }}
        >
          {translations.words.OpenInNewTab[language]}
        </MenuItem>
        <MenuItem
          onClick={() => {
            const link = `/${customerId}/${menuImageId}/annotate${window.location.search}`;
            window.open(link, "_blank");
            setAnchorEl(null);
            setMenuImageId(null);
          }}
        >
          {translations.words.EditInNewTab[language]}
        </MenuItem>
      </Menu>

      {(thumbnailRegexPattern || thumbnailPoleItemPlacement) && (
        <Tooltip
          title={
            <Stack spacing={1}>
              <Typography fontSize="small">
                The regex filter for pattern "{thumbnailRegexPattern}" is active
              </Typography>
              <Typography fontSize="small">
                Some images may be missing from the slider
              </Typography>
              <Button
                sx={{ fontSize: "small", color: "white" }}
                variant="outlined"
                onClick={() => {
                  setThumbnailRegexPattern(null);
                  setThumbnailPoleItemPlacement(null);
                }}
              >
                Remove regex filter
              </Button>
            </Stack>
          }
          arrow
        >
          <Info
            style={{
              position: "absolute",
              bottom: 5,
              left: 10,
              color: "#1876D1",
              background: "#FFF",
              borderRadius: "50%",
            }}
          />
        </Tooltip>
      )}
    </div>
  );
}
