import React from "react";
import RegionSelector from "../RegionSelectorSidebarBox";
import { Switch, FormControlLabel, Tooltip } from "@mui/material";
import translations from "translations";
import "./style.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLanguage } from "hooks";
import { useUserCanEditDetections } from "views/AnnotationTool/hooks/useUserCanEditDetections";

export const Sidebar = function Sidebar({
  regions,
  onSelectRegion,
  onChangeRegion,
  onDeleteRegion,
  issueCategories,
  detectedCategories,
  objectTypes,
  issueSeverities,
  skyqraft_employee,
  setSavedType,
  savedRegions,
  selectedType,
  setSelectedType,
  onDuplicateRegion,
  showHumanMachine,
  setShowHumanMachine,
  user,
  onMLReviewChange,
  annotatorObjectColor,
}) {
  const canEditDetections = useUserCanEditDetections();
  const allRegionsVisible = regions.every((r) => r.visible);
  const { language } = useLanguage();
  return (
    <div>
      <div className="generalTools">
        {canEditDetections && (
          <FormControlLabel
            control={
              <Switch
                checked={showHumanMachine}
                onChange={(e) => setShowHumanMachine(!showHumanMachine)}
              />
            }
            label={translations.AnnotationTool.HumanMachine[language]}
            labelPlacement="end"
          />
        )}

        <FormControlLabel
          control={
            <Switch
              checked={allRegionsVisible}
              onChange={(e) =>
                regions.forEach((r) => {
                  onChangeRegion({ ...r, visible: !allRegionsVisible });
                })
              }
            />
          }
          label={
            <Tooltip
              disableInteractive
              title={translations.AnnotationTool.VisibilityAll[language]}
              placement="right"
            >
              {allRegionsVisible ? (
                <Visibility sx={{ pt: 0.5 }} />
              ) : (
                <VisibilityOff sx={{ pt: 0.5 }} />
              )}
            </Tooltip>
          }
          labelPlacement="end"
        />
      </div>
      <RegionSelector
        regions={regions}
        onSelectRegion={onSelectRegion}
        onChangeRegion={onChangeRegion}
        onDeleteRegion={onDeleteRegion}
        setSavedType={setSavedType}
        savedRegions={savedRegions}
        issueCategories={issueCategories}
        detectedCategories={detectedCategories}
        objectTypes={objectTypes}
        issueSeverities={issueSeverities}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        skyqraft_employee={skyqraft_employee}
        onDuplicateRegion={onDuplicateRegion}
        user={user}
        onMLReviewChange={onMLReviewChange}
        annotatorObjectColor={annotatorObjectColor}
      />
    </div>
  );
};
export default Sidebar;
