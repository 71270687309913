import { useMemo, useState } from "react";
import { useProjectStatistics } from "./hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import BarChart from "./BarChart";
import Summary from "./Summary";
import SortingButton, { SortingOptions } from "./SortingButton";
import type { buttonMethodType } from "./SortingButton";
import ExpandMoreTitle from "./ExpandMoreTitle";
import { useLanguage } from "hooks";
import translations from "./translations";

export default function ProjectStatistics() {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [sortMethod, setSortMethod] = useState<buttonMethodType>(
    SortingOptions.defects
  );

  // Extract project statitics from the API via this hook
  const { projectStatistics, loading } = useProjectStatistics();
  const { language } = useLanguage();

  // Filter and sort the statistics
  const filteredStats = useMemo(
    () => projectStatistics.filter((project) => project.n_defects > 0),
    [projectStatistics]
  );
  const stats = useMemo(
    () =>
      filteredStats.sort((a, b) => {
        // If we are sorting on defects, do that
        if (sortMethod === SortingOptions.defects) {
          return a.n_defects - b.n_defects;
        }
        // If we are sorting on processed defects, do that
        if (sortMethod === SortingOptions.processed) {
          return a.n_processed_defects - b.n_processed_defects;
        }
        // If we are sorting on unprocessed defects, do that
        if (sortMethod === SortingOptions.unprocessed) {
          return (
            a.n_defects -
            a.n_processed_defects -
            (b.n_defects - b.n_processed_defects)
          );
        }
        // If we have no sorting, sort by project id
        return a.project.id - b.project.id;
      }),
    [filteredStats, sortMethod]
  );

  return (
    <Stack direction="column" mx={2}>
      <Summary stats={stats} loading={loading} />
      <Accordion
        component={Box}
        sx={{ backgroundColor: "transparent", boxShadow: "none" }}
        disableGutters
        expanded={accordionOpen}
        onChange={(_, expanded) => setAccordionOpen(expanded)}
      >
        <AccordionSummary sx={{ px: 0 }}>
          <ExpandMoreTitle open={accordionOpen}>
            {accordionOpen
              ? translations.ShowLess[language]
              : translations.ShowMore[language]}
          </ExpandMoreTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h5">
            {translations.ProjectWithMostUnprocessedDefects[language]}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            {translations.SortBy[language]}
          </Typography>
          <Grid container spacing={2}>
            <SortingButton
              buttonMethod={SortingOptions.defects}
              currentSortingMethod={sortMethod}
              setSortMethod={setSortMethod}
            >
              {translations.TotalDefects[language]}
            </SortingButton>
            <SortingButton
              buttonMethod={SortingOptions.processed}
              currentSortingMethod={sortMethod}
              setSortMethod={setSortMethod}
            >
              {translations.Processed[language]}
            </SortingButton>
            <SortingButton
              buttonMethod={SortingOptions.unprocessed}
              currentSortingMethod={sortMethod}
              setSortMethod={setSortMethod}
            >
              {translations.Unprocessed[language]}
            </SortingButton>
          </Grid>
          <BarChart
            stats={stats}
            sortingMethod={sortMethod}
            loading={loading}
          />
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
