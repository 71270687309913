import { connect } from "react-redux";
import { RootState } from "state/store";
import MapToggle from "./MapToggle";
import {
  setZoneVisibility,
  setMarkerVisibility,
  setPowerlineVisibility,
  setPoleVisibility,
  setVisibleImageTypes,
  setAssignmentVisibility,
  setClearanceVisibility,
  setLidarMarkerVisibility,
  updateMarkers,
} from "state/actions";
import { ImageType } from "interfaces";

interface IStateProps {
  showMarkers: boolean;
  showPowerlines: boolean;
  showZones: boolean;
  inspectorMode: boolean;
  showPoles: boolean;
  skyqraftEmployee: boolean;
  imageTypes: ImageType[];
  visibleTypes: number[];
  showAssignments: boolean;
  assignmentsExist: boolean;
  showClearanceMarkers: boolean;
  showLidarMarkers: boolean;
}

interface IDispatchProps {
  setZoneVisibility: typeof setZoneVisibility;
  setMarkerVisibility: typeof setMarkerVisibility;
  setPowerlineVisibility: typeof setPowerlineVisibility;
  // @ts-ignore
  setPoleVisibility: (boolean) => void;
  setVisibleImageTypes: (image_types: number[]) => void;
  // @ts-ignore
  setAssignmentVisibility: (boolean) => void;
  setClearanceVisibility: (visibility: boolean) => void;
  setLidarMarkerVisibility: (visibility: boolean) => void;
  updateMarkers: () => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    showMarkers: state.ui.showMarkers,
    showPowerlines: state.ui.showPowerlines,
    showZones: state.ui.showZones,
    inspectorMode: state.map.inspectorMode,
    showPoles: state.ui.showPoles,
    skyqraftEmployee: state.user.skyqraft_employee,
    imageTypes: state.image.types,
    visibleTypes: state.map.visibleImageTypes,
    showAssignments: state.ui.showAssignments,
    assignmentsExist: state.map.overlays.length > 0,
    showClearanceMarkers: state.ui.showClearanceMarkers,
    showLidarMarkers: state.ui.showLidarMarkers,
  };
};

const mapDispatchToProps = {
  setZoneVisibility,
  setMarkerVisibility,
  setPowerlineVisibility,
  setPoleVisibility,
  setVisibleImageTypes,
  setAssignmentVisibility,
  setClearanceVisibility,
  setLidarMarkerVisibility,
  updateMarkers,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(MapToggle);
