import { EventHint, captureException } from "@sentry/react";
import { axiosInstance } from "utils/request";
import z from "zod";

const Schema = z.array(
  z.object({
    project: z.object({
      id: z.number(),
      name: z.string(),
      year: z.number(),
    }),
    n_defects: z.number(),
    n_processed_defects: z.number(),
  })
);

export async function getProjectStatistics() {
  try {
    const response = await axiosInstance.get("/project/statistics");
    const parsedResponse = Schema.parse(response.data);
    return parsedResponse;
  } catch (error) {
    const event_id = "project-statistics-api-error";
    const context: EventHint = {
      event_id,
    };
    captureException(error, context);
    return [];
  }
}
