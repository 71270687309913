import { connect } from "react-redux";
import Component from "./Pole";
import { RootState } from "state/store";
import { setClusterMethod, updatePoles } from "state/actions";

interface IStateProps {
  customerID: number;
  skyqraftEmployee: boolean;
  superuser: boolean;
  demoMode: boolean;
}

interface IDispatchProps {
  setClusterMethod: (method: string) => void;
  updatePoles: Function;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    customerID: state.mission.id,
    skyqraftEmployee: state.user.skyqraft_employee,
    superuser: state.user.superuser,
    demoMode: state.ui.demoMode,
  };
};

const mapDispatchToProps = {
  setClusterMethod,
  updatePoles,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
