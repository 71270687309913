import { useContext } from "react";
import { MenuItem, Typography, ListItemIcon } from "@mui/material";
import { Done, Clear } from "@mui/icons-material";
import { authorizedPost } from "utils/request";
import { toast } from "react-toastify";
import { updatePowerlineColor } from "state/actions";
import store from "state/store";
import {
  featureIsInSelection,
  featureIsPowerline,
  assertNewFeatureState,
  featureIsInFilteredFeedbay,
} from "utils";
import { context as WorkSessionContext } from "providers/WorkSessionProvider";
import { useFeedBayFilter } from "hooks/filter/search_parameters/useFeedBayFilter";
import { useFilterPlay } from "hooks/filter/useFilterPlay";
import { useSelector } from "hooks";

interface IProps {
  selection: google.maps.Rectangle;
}

export default function AnnotatorInspectionItem({ selection }: IProps) {
  const gmap = useSelector((state) => state.map.gmap);
  const workSessionContext = useContext(WorkSessionContext);
  const { feedBayFilter } = useFeedBayFilter();
  const { filterActive } = useFilterPlay();

  function onClick(value: boolean) {
    // @ts-ignore
    const selectionBounds = selection.getBounds().toJSON();

    // Construct database post request
    const body = {
      annotator_inspected: value,
      ...selectionBounds,
    };

    //Send data to databse
    authorizedPost("/powerline/annotatorinspection", body)
      .then(() => {
        workSessionContext.update();
        // @ts-ignore
        const inspectionFeatures = [];
        if (gmap) {
          // biome-ignore lint/complexity/noForEach: <explanation>
          gmap.data.forEach((feature) => {
            if (
              featureIsPowerline(feature) &&
              featureIsInSelection(feature, selection) &&
              featureIsInFilteredFeedbay(
                feature,
                feedBayFilter,
                filterActive
              ) &&
              assertNewFeatureState(feature, "annotator_inspected")
            ) {
              inspectionFeatures.push(feature);
            }
          });
          // Use store.dispatch to avoid circular dependencies
          store.dispatch(
            // @ts-ignore
            updatePowerlineColor(
              // @ts-ignore
              inspectionFeatures,
              "annotator_inspected",
              value,
              gmap
            )
          );
        }
      })
      .catch(() => {
        toast.error("Failed to update powerlines");
      });
  }
  return (
    <MenuItem>
      <ListItemIcon
        onClick={() => {
          onClick(true);
        }}
        style={{ minWidth: "auto", marginRight: "10px" }}
      >
        <Done />
      </ListItemIcon>
      <ListItemIcon
        onClick={() => {
          onClick(false);
        }}
        style={{ minWidth: "auto", marginRight: "10px" }}
      >
        <Clear />
      </ListItemIcon>
      <Typography>Annotator analysis</Typography>
    </MenuItem>
  );
}
