import { useEffect, useMemo, useState } from "react";
import { Tooltip, IconButton, Collapse } from "@mui/material";
import { AccessTime as AccessTimeIcon, Add } from "@mui/icons-material";
import History from "./SelectedItem/History";
import ClassList from "./ClassList";
import { checkRegionUpdate } from "../Utils";
import "./style.scss";
import translations from "translations";
import ContextMenuTop from "./EditWorkflowContextMenuTop";
import { Divider, Chip } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getClientObjectTypeName } from "utils/utils";
import { groupBy, mapValues } from "lodash";
import hasAccess from "utils/authTools";
import { useLanguage } from "hooks";
import { useUserCanEditDetections } from "views/AnnotationTool/hooks/useUserCanEditDetections";

const NOT_SAVEABLE = "#FDD";
const NEW_OBJECT = "#DFD";
const EDITED_OBJECT = "#FFD";

export const RegionSelectorSidebarBox = function RegionSelectorSidebarBox(
  _ref4
) {
  const { language } = useLanguage();
  const regions = _ref4.regions;
  const savedRegions = _ref4.savedRegions;
  const onDeleteRegion = _ref4.onDeleteRegion;
  const onChangeRegion = _ref4.onChangeRegion;
  const onSelectRegion = _ref4.onSelectRegion;
  const objectTypes = _ref4.objectTypes;
  const issueSeverities = _ref4.issueSeverities;
  const setSavedType = _ref4.setSavedType;
  const setSelectedType = _ref4.setSelectedType;
  const onMLReviewChange = _ref4.onMLReviewChange;
  const issueCategories = _ref4.issueCategories;
  const detectedCategories = _ref4.detectedCategories;
  const annotatorObjectColor = _ref4.annotatorObjectColor;
  const user = _ref4.user;
  const [propertiesVisible, showProperties] = useState(false);
  const [memoSortedRegions, setMemoSortedRegions] = useState([]);
  const [defectRegions, setDefectRegions] = useState([]);
  const [objectRegions, setObjectRegions] = useState([]);
  const canUpdateDetections = useUserCanEditDetections();

  let newRegions = memoSortedRegions;

  const objectTypesNew = objectTypes.map((e) => getClientObjectTypeName(e.id));

  const sortedRegions = useMemo(() => {
    let shouldReOrderList = [...regions]
      .map((e) =>
        checkRegionUpdate(e, savedRegions, objectTypesNew, issueCategories)
      )
      .filter((f) => !f.saved);
    if (shouldReOrderList.length > 0) {
      shouldReOrderList = false;
    } else {
      shouldReOrderList = true;
    }

    const sorted = [...regions].sort((a, b) => {
      //New objects contains n, sort them first
      if (a.id.includes("n") && !b.id.includes("n")) {
        return -1;
      }
      if (!a.id.includes("n") && b.id.includes("n")) {
        return 1;
      }

      return (
        Math.min(...a.severities.filter((c) => !!c)) -
          Math.min(...b.severities.filter((c) => !!c)) ||
        (!!a.types[0] &&
        objectTypesNew.find((ot) => ot.id === a.types[0]).name >
          (!!b.types[0] &&
            objectTypesNew.find((ot) => ot.id === b.types[0]).name)
          ? 1
          : -1)
      );
    });

    //Remember objects order before save
    const sortedMemo = memoSortedRegions
      .map((a) => {
        const newRegions = [...regions].filter((f) => f.id === a.id);

        return newRegions[0];
      })
      .filter((f) => !!f);

    [...regions].map((r) => {
      if (r.id.includes("n") && !sortedMemo.find((e) => e.id === r.id)) {
        sortedMemo.unshift(r);
      }
    });

    if (shouldReOrderList) {
      newRegions = sorted;
    } else {
      newRegions = sortedMemo;
    }

    setMemoSortedRegions(newRegions);

    return newRegions;
  }, [regions, language, objectTypes]);

  const groupedIds = [];

  const userHasAccess = hasAccess("annotationToolEditObjects");

  useEffect(() => {
    setDefectRegions(sortedRegions.filter((r) => r.is_defect.includes(true)));
    setObjectRegions(sortedRegions.filter((r) => r.is_defect.every((i) => !i)));

    //Group same objects together
    objectGroups.map((e, i) =>
      e.map((h) => !!h.highlighted).some((c) => c === true)
        ? e.map((f, index) =>
            groupedIds.push({
              object: [f],
              length: 1,
              ExpandedPosition:
                e.length > 1 &&
                (e.length - 1 === index ? "last" : index === 0 && "first"),
            })
          )
        : groupedIds.push({ object: e, length: e.length })
    );
  }, [sortedRegions]);

  const objectRegionsUnOrdered = mapValues(objectRegions, (val) => {
    const clientTypes = val.types.map(
      (v) => getClientObjectTypeName(v).client_object_type_id
    );
    return {
      ...val,
      client_types: clientTypes,
    };
  });

  let objectGroups = groupBy(objectRegionsUnOrdered, "client_types");
  objectGroups = Object.values(objectGroups);

  //Keep the original order before grouping
  objectGroups.sort((a, b) => {
    const indexA = sortedRegions.findIndex((obj) => obj.id === a[0].id);
    const indexB = sortedRegions.findIndex((obj) => obj.id === b[0].id);

    if (indexA < indexB) {
      return -1;
    }
    if (indexA > indexB) {
      return 1;
    }
    return 0;
  });

  objectGroups.map((e, i) =>
    e.map((h) => !!h.highlighted).some((c) => c === true)
      ? e.map((f, index) =>
          groupedIds.push({
            object: [f],
            length: 1,
            ExpandedPosition:
              e.length > 1 &&
              (e.length - 1 === index ? "last" : index === 0 && "first"),
          })
        )
      : groupedIds.push({ object: e, length: e.length })
  );

  return (
    <div className="objectCollectorList">
      {defectRegions.length > 0 && (
        <Divider sx={{ py: 1 }}>
          <Chip label="Defects" />
        </Divider>
      )}
      {defectRegions.map((region, index) => {
        const isOpen = region.highlighted;
        const regionState = checkRegionUpdate(
          region,
          savedRegions,
          objectTypesNew,
          issueCategories
        );
        return (
          <div className="objectCollector" key={`${index}`}>
            <div
              className="collectorTitle"
              id="clickExpand"
              style={{
                backgroundColor: !regionState.canSave
                  ? NOT_SAVEABLE
                  : regionState.new
                    ? NEW_OBJECT
                    : !regionState.saved
                      ? EDITED_OBJECT
                      : undefined,
              }}
              onClick={(event) => {
                if (event.target.id === "clickExpand") {
                  setSelectedType(region.highlighted ? 0 : region.types[0]);
                  region.highlighted
                    ? onChangeRegion({
                        ...region,
                        highlighted: false,
                        editingLabels: false,
                      })
                    : onSelectRegion(region);
                }
              }}
            >
              <div className="horizontal center">
                <div className="colorDots">
                  {[...region.types].map((o, i) => {
                    return (
                      <div
                        key={`${i}`}
                        className="dot"
                        style={{
                          zIndex: region.types.length - i,
                          backgroundColor: o
                            ? annotatorObjectColor
                              ? objectTypesNew.find((ot) => ot.id === o)
                                  .class_color
                              : objectTypesNew.find((ot) => ot.id === o).color
                            : "#DDD",
                        }}
                      />
                    );
                  })}
                </div>
                <div className="titleText">
                  <h2 id="clickExpand">
                    {objectTypesNew.find((ot) => ot.id === region.types[0])
                      ? objectTypesNew.find((ot) => ot.id === region.types[0])
                          .name
                      : "New thing"}
                  </h2>
                </div>
              </div>
              <div className="horizontal center">
                {region.types.length < 2 && (
                  <ContextMenuTop
                    user={user}
                    language={language}
                    menuPayload={{
                      onChangeRegion: onChangeRegion,
                      region: region,
                      index: 0,
                      supervisor: user.supervisor,
                    }}
                  />
                )}
              </div>
            </div>
            <Collapse in={isOpen}>
              {isOpen && (
                <div className="objectList">
                  <ClassList
                    region={region}
                    objectTypes={objectTypesNew}
                    onChangeRegion={onChangeRegion}
                    onDeleteRegion={onDeleteRegion}
                    language={language}
                    setSelectedType={setSelectedType}
                    setSavedType={setSavedType}
                    savedRegions={savedRegions}
                    onMLReviewChange={onMLReviewChange}
                    skyqraft_employee={user.skyqraft_employee}
                    supervisor={user.supervisor}
                    userId={user.userId}
                    user={user}
                    issueCategories={issueCategories}
                    detectedCategories={detectedCategories}
                    issueSeverities={issueSeverities}
                    annotatorObjectColor={annotatorObjectColor}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: 5,
                      zIndex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="classRow center" key={"new1"}>
                        {canUpdateDetections && (
                          <Tooltip
                            title={
                              translations.AnnotationTool.addDetection[language]
                            }
                            placement="top"
                          >
                            <IconButton
                              onClick={() =>
                                onChangeRegion({
                                  ...region,
                                  types: [...region.types, null],
                                  severities: [...region.severities, null],
                                  workflow_status: [
                                    ...region.workflow_status,
                                    2,
                                  ],
                                  processed: [...region.processed, null],
                                  type_id: [...region.type_id, null],
                                  categories: [...region.categories, null],
                                  is_defect: [...region.is_defect, false],
                                  type_comment: [...region.type_comment, ""],
                                  skyqraft_hidden: [
                                    ...region.skyqraft_hidden,
                                    false,
                                  ],
                                })
                              }
                              style={{ padding: 5 }}
                            >
                              <Add htmlColor="#119911" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>

                      <div className="classRow center" key={"new2"}>
                        <Tooltip
                          title={
                            translations.AnnotationTool.addDefect[language]
                          }
                          placement="top"
                        >
                          <IconButton
                            onClick={() =>
                              onChangeRegion({
                                ...region,
                                types: [...region.types, null],
                                severities: [...region.severities, null],
                                workflow_status: [...region.workflow_status, 2],
                                categories: [...region.categories, null],
                                type_comment: [...region.type_comment, ""],
                                is_defect: [...region.is_defect, true],
                                processed: [...region.processed, null],
                                type_id: [...region.type_id, null],
                                skyqraft_hidden: [
                                  ...region.skyqraft_hidden,
                                  false,
                                ],
                              })
                            }
                            style={{ padding: 5 }}
                          >
                            <Add htmlColor="#FF4444" />
                          </IconButton>
                        </Tooltip>
                      </div>
                      {canUpdateDetections && (
                        <IconButton
                          style={{ padding: 5 }}
                          onClick={() =>
                            onChangeRegion({
                              ...region,
                              showHistory: !region.showHistory,
                              showMessages: false,
                            })
                          }
                        >
                          <AccessTimeIcon />
                        </IconButton>
                      )}
                    </div>
                    <History
                      region={region}
                      objectTypes={objectTypesNew}
                      issueSeverities={issueSeverities}
                      language={language}
                    />
                  </div>
                </div>
              )}
            </Collapse>
          </div>
        );
      })}
      {objectRegions.length > 0 && (
        <Divider sx={{ py: 1 }}>
          <Chip label="Objects" />
        </Divider>
      )}
      {groupedIds.map((regionIn, index) => {
        const region = regionIn.object[0];
        let isOpen;
        if (!userHasAccess) {
          isOpen = false;
        } else {
          isOpen = region.highlighted;
        }
        const regionState = checkRegionUpdate(
          region,
          savedRegions,
          objectTypesNew,
          issueCategories
        );
        return (
          <>
            {regionIn.ExpandedPosition === "first" && (
              <Divider sx={{ my: 1, mx: 2 }} />
            )}
            <div className="objectCollector" key={`${index}`}>
              <div
                className="collectorTitle"
                id="clickExpand"
                style={{
                  backgroundColor: canUpdateDetections
                    ? !regionState.canSave
                      ? NOT_SAVEABLE
                      : regionState.new
                        ? NEW_OBJECT
                        : !regionState.saved
                          ? EDITED_OBJECT
                          : undefined
                    : region.highlighted
                      ? EDITED_OBJECT
                      : undefined,
                }}
                onClick={(event) => {
                  if (!canUpdateDetections) {
                    setSelectedType(region.highlighted ? 0 : region.types[0]);
                    region.highlighted
                      ? onChangeRegion({
                          ...region,
                          highlighted: false,
                          editingLabels: false,
                        })
                      : onSelectRegion({ ...region, highlighted: true });
                    isOpen = false;
                    return;
                  }
                  if (event.target.id === "clickExpand") {
                    setSelectedType(region.highlighted ? 0 : region.types[0]);
                    region.highlighted
                      ? onChangeRegion({
                          ...region,
                          highlighted: false,
                          editingLabels: false,
                        })
                      : onSelectRegion(region);
                  }
                }}
              >
                <div className="horizontal center">
                  <div className="colorDots">
                    {[...region.types].map((o, i) => {
                      if (objectTypesNew.find((ot) => ot.id === o)) {
                        return (
                          <div
                            key={`${i}`}
                            className="dot"
                            style={{
                              zIndex: region.types.length - i,
                              backgroundColor: o
                                ? annotatorObjectColor
                                  ? objectTypesNew.find((ot) => ot.id === o)
                                      .class_color
                                  : objectTypesNew.find((ot) => ot.id === o)
                                      .color
                                : "#DDD",
                            }}
                          />
                        );
                      }
                    })}
                  </div>
                  <div className="titleText">
                    <h2 id="clickExpand">
                      {objectTypesNew.find((ot) => ot.id === region.types[0])
                        ? objectTypesNew.find((ot) => ot.id === region.types[0])
                            .name
                        : "New thing"}
                    </h2>
                  </div>
                </div>

                {regionIn.length > 1 ? (
                  <div
                    className="titleText horizontal center"
                    style={{ marginLeft: 2, marginRight: 11 }}
                  >
                    <div style={{ paddingTop: 10 }}>
                      <div
                        id="clickExpand"
                        style={{ fontSize: 14, textAlign: "center" }}
                      >
                        {regionIn.length}
                      </div>
                      <ExpandMoreIcon id="clickExpand" size="large" />
                    </div>
                  </div>
                ) : (
                  <div className="horizontal center">
                    {region.types.length < 2 && (
                      <ContextMenuTop
                        user={user}
                        language={language}
                        menuPayload={{
                          onChangeRegion: onChangeRegion,
                          region: region,
                          index: 0,
                          supervisor: user.supervisor,
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              <Collapse in={isOpen}>
                {isOpen && (
                  <div className="objectList">
                    <ClassList
                      region={region}
                      objectTypes={objectTypesNew}
                      onChangeRegion={onChangeRegion}
                      onDeleteRegion={onDeleteRegion}
                      language={language}
                      setSelectedType={setSelectedType}
                      setSavedType={setSavedType}
                      savedRegions={savedRegions}
                      onMLReviewChange={onMLReviewChange}
                      skyqraft_employee={user.skyqraft_employee}
                      supervisor={user.supervisor}
                      userId={user.userId}
                      user={user}
                      issueCategories={issueCategories}
                      detectedCategories={detectedCategories}
                      issueSeverities={issueSeverities}
                      annotatorObjectColor={annotatorObjectColor}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 5,
                        zIndex: 1,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="classRow center" key={"new1"}>
                          <Tooltip
                            title={
                              translations.AnnotationTool.addDetection[language]
                            }
                            placement="top"
                          >
                            <IconButton
                              onClick={() =>
                                onChangeRegion({
                                  ...region,
                                  types: [...region.types, null],
                                  severities: [...region.severities, null],
                                  workflow_status: [
                                    ...region.workflow_status,
                                    2,
                                  ],
                                  type_comment: [...region.type_comment, ""],
                                  processed: [...region.processed, null],
                                  type_id: [...region.type_id, null],
                                  categories: [...region.categories, null],
                                  is_defect: [...region.is_defect, false],
                                  skyqraft_hidden: [
                                    ...region.skyqraft_hidden,
                                    false,
                                  ],
                                })
                              }
                              style={{ padding: 5 }}
                            >
                              <Add htmlColor="#119911" />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div className="classRow center" key={"new2"}>
                          <Tooltip
                            title={
                              translations.AnnotationTool.addDefect[language]
                            }
                            placement="top"
                          >
                            <IconButton
                              onClick={() =>
                                onChangeRegion({
                                  ...region,
                                  types: [...region.types, null],
                                  severities: [...region.severities, null],
                                  workflow_status: [
                                    ...region.workflow_status,
                                    2,
                                  ],
                                  type_comment: [...region.type_comment, ""],
                                  processed: [...region.processed, null],
                                  type_id: [...region.type_id, null],
                                  categories: [...region.categories, null],
                                  is_defect: [...region.is_defect, true],
                                  skyqraft_hidden: [
                                    ...region.skyqraft_hidden,
                                    false,
                                  ],
                                })
                              }
                              style={{ padding: 5 }}
                            >
                              <Add htmlColor="#FF4444" />
                            </IconButton>
                          </Tooltip>
                        </div>
                        {canUpdateDetections && (
                          <IconButton
                            style={{ padding: 5 }}
                            onClick={() =>
                              onChangeRegion({
                                ...region,
                                showHistory: !region.showHistory,
                                showMessages: false,
                              })
                            }
                          >
                            <AccessTimeIcon />
                          </IconButton>
                        )}
                      </div>
                      <History
                        region={region}
                        objectTypes={objectTypesNew}
                        issueSeverities={issueSeverities}
                        language={language}
                      />
                    </div>
                  </div>
                )}
              </Collapse>
            </div>
            {regionIn.ExpandedPosition === "last" && (
              <Divider sx={{ py: 0, mx: 2 }}>
                <Chip
                  label={<ExpandLessIcon htmlColor="#616161" />}
                  size="small"
                  onClick={() => {
                    groupedIds.map((r) => {
                      r.ExpandedPosition !== undefined &&
                        onChangeRegion({
                          ...r.object[0],
                          highlighted: false,
                          editingLabels: false,
                        });
                    });
                  }}
                />
              </Divider>
            )}
          </>
        );
      })}
    </div>
  );
};

export default RegionSelectorSidebarBox;
