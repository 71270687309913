import { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { useSelector, useLanguage } from "hooks";
import { workflowStatuses } from "constants/imageReview";
import {
  context as AnnotationContext,
  ISpecialAnnotation,
} from "../provider/AnnotationProvider/context";
import translations from "../translations";

export default function VerifyAllButton() {
  const { setAnnotations, annotations } = useContext(AnnotationContext);

  const { language } = useLanguage();
  const objectTypes = useSelector((state) => state.objects.objectTypes);

  const isDefect = (typeId: number) => {
    return objectTypes.find((o) => o.id === typeId)?.issue;
  };
  const objectIsUnverified = (args: {
    workflow_status: number;
    index: number;
    annotation: ISpecialAnnotation;
  }) => {
    const { workflow_status, index, annotation } = args;

    return (
      workflow_status === workflowStatuses.REVIEW_REQUESTED &&
      !isDefect(annotation.types[index])
    );
  };

  const setWorkflow = () => {
    const newAnnotations = annotations.map((annotation) => {
      const workflow = annotation.workflow_status.map(
        (w: number, i: number) => {
          if (
            objectIsUnverified({ workflow_status: w, index: i, annotation })
          ) {
            return workflowStatuses.TRUE_POSITIVE;
          }
          return w;
        }
      );
      return { ...annotation, workflow_status: workflow };
    });
    setAnnotations(newAnnotations);
  };

  const unverifiedObjectExists = annotations.some((annotation) => {
    return annotation.workflow_status.some((w: number, i: number) => {
      return objectIsUnverified({ workflow_status: w, index: i, annotation });
    });
  });

  return (
    <Tooltip
      title={
        unverifiedObjectExists
          ? translations.VerifyAllObjects[language]
          : translations.AllObjectsVerified[language]
      }
      arrow
      placement="top"
      disableInteractive
    >
      <IconButton
        onClick={setWorkflow}
        style={{ width: "42px", height: "42px" }}
      >
        {unverifiedObjectExists ? <CheckBox /> : <CheckBox htmlColor="green" />}
      </IconButton>
    </Tooltip>
  );
}
